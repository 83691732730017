import React, { useState } from 'react';
import { NavItem } from '@flexera/shell.navigation';
import { MenuItem } from '../MenuItem/MenuItem';
import { MenuContentStyled } from './MenuContent.styled';

interface MenuProps {
	items: NavItem[];
	searchWord?: string;
}

export const MenuContent = ({ items, searchWord }: MenuProps) => {
	const [flyoutOpen, setFlyoutOpen] = useState(false);
	let menuContentTimer = null;
	let flyoutLeaveTimer = null;
	const handleMouseOver = (e) => {
		clearTimeout(flyoutLeaveTimer);
		if (e.target.classList.contains('navlink')) {
			setFlyoutOpen(false);
			return;
		}
		if (!flyoutOpen) {
			menuContentTimer = setTimeout(() => {
				setFlyoutOpen(true);
			}, 400);
		}
	};

	const handleFlyoutLeave = () => {
		flyoutLeaveTimer = setTimeout(() => {
			setFlyoutOpen(false);
		}, 50);
	};

	const handleContentMouseLeave = () => {
		clearTimeout(menuContentTimer);
		setFlyoutOpen(false);
	};
	return (
		<MenuContentStyled
			role={'menubar'}
			tabIndex={-1}
			onMouseOver={(e) => handleMouseOver(e)}
			onMouseLeave={() => handleContentMouseLeave()}
			flyoutOpen={flyoutOpen}
		>
			{items.map((item, index) => (
				<MenuItem
					index={index}
					item={item}
					key={item.id ? item.id : `${item.label}${index}`}
					searchWord={searchWord}
					setFlyoutOpen={setFlyoutOpen}
					handleFlyoutLeave={handleFlyoutLeave}
				/>
			))}
		</MenuContentStyled>
	);
};
