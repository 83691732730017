import React, { FC, useEffect } from 'react';
import { Admin } from '@flexera/admin.bootstrap';
import { useAuthenticated } from '@flexera/auth.base';
import { useFnmsIframe } from '@flexera/flexera-one.feature-flags';
import { FNMSCookieRefreshIframe } from '@flexera/fnms.bootstrap';
import { Governance } from '@flexera/governance.bootstrap';
import { Settings } from '@flexera/settings.bootstrap';
import { loadTagManager } from '@flexera/settings.main';
import { AppLayout } from '@flexera/shell.app-layout';
import { useUpdateGTMData } from '@flexera/shell.google-tag-manager';
import { AmChartsVisualizations } from '@flexera/data-visualization.amcharts';
import { PlotlyVisualizations } from '@flexera/data-visualization.plotly';
import { AgGridVisualization } from '@flexera/data-visualization.ag-grid';
import { CoreVisualizations } from '@flexera/data-visualization.core';
import { CarouselVisualization } from '@flexera/data-visualization.carousel';
import { Stratus } from '@flexera/stratus.bootstrap';
import { Nav } from './Nav';
import { useAppRoutes } from './useAppRoutes';

export const InitApp: FC = () => {
	useUpdateGTMData();
	const showFnms = useFnmsIframe();
	useAppRoutes();
	const authenticated = useAuthenticated();

	useEffect(() => {
		if (authenticated) {
			if (localStorage.getItem('auth.starttime') === null) {
				localStorage.setItem('auth.starttime', String(Date.now()));
			}
			// Don't try to load tag manager until we know the user is authenticated
			loadTagManager();
		}
	}, [authenticated]);

	return (
		<Nav>
			<AppLayout />
			{showFnms && <FNMSCookieRefreshIframe />}
			<Governance />
			<Settings />
			<Admin />
			<Stratus />
			<AmChartsVisualizations />
			<PlotlyVisualizations />
			<AgGridVisualization />
			<CoreVisualizations />
			<CarouselVisualization />
		</Nav>
	);
};
