import { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { NavContext } from './context/NavContext';

/**
 * Return the nav item closest to matching the current URL
 */
export function useClosestNavItem() {
	const { pathname } = useLocation();
	const { items } = useContext(NavContext);

	function lineage(id: string) {
		const value: string[] = [];
		let item = items[id];
		while (item) {
			value.unshift(item.id.toString());
			item = items[item.parentId];
		}
		return value;
	}

	const itemId = Object.keys(items)
		// take all nav items that match the path
		.filter((id) => items[id].urlMatch?.test(pathname))
		// get the lineage
		.map(lineage)
		// sort from shorter to longer
		.sort((a, b) => a.length - b.length)
		// take the longer one, in other words the leaf item lineage
		?.pop()
		// get the id of the last item in the lineage
		?.pop();
	return items[itemId];
}
