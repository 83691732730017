import { useRef, useEffect } from 'react';

/**
 *
 * @param callback
 * @param wait
 */
export const useDebounce = <A extends any[]>(
	callback: (...args: A) => void,
	wait: number
): (() => void) => {
	// track args & timeout handle between calls
	const argsRef = useRef<A>();
	const timeout = useRef<ReturnType<typeof setTimeout>>();

	const cleanup = (): void => {
		if (timeout.current) {
			clearTimeout(timeout.current);
		}
	};

	// make sure our timeout gets cleared if
	// our consuming component gets unmounted
	useEffect(() => cleanup, []);

	const debouncedCallback = (...args: A): void => {
		// capture latest args
		argsRef.current = args;

		// clear debounce timer
		cleanup();

		// start waiting again
		timeout.current = setTimeout(() => {
			if (argsRef.current) {
				callback(...argsRef.current);
			}
		}, wait);
	};

	return debouncedCallback;
};
