import React, { useState, FC, useEffect } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import {
	callTokenRefresh,
	resetTokenRefresh,
	setSessionWillTimeoutAction,
	setSessionHasEndedAction
} from '@flexera/lib.auth';
import { useUserSession, useTokenState } from '@flexera/auth.iam';
import { H1, Slideout, SlideoutContent } from '@flexera/ui.component-library';
import { useDebounce } from '@flexera/shell.splash';
import { useDispatch } from 'react-redux';
import { DateTime } from 'luxon';
import { BroadcastChannel } from 'broadcast-channel';
import { t } from 'ttag';
import {
	XMinutes,
	checkSessionHasExpiredAndIsActive,
	checkSessionHasTimedOut,
	checkSessionWillTimeOut
} from './SessionLogic';
import { TableWrapper } from './Styled';

const inactivityTimeout = 5000; // 5 second window to detect inactivity
const sessionBroadcast: BroadcastChannel = new BroadcastChannel('f1_session');
// E2E Overide
const e2eOverride = localStorage.getItem('e2e.lastActive');

/**
 * This component sits at the root of the app,
 * it controls the session activity and token refresh logic.
 * It broadcasts token refreshes to all active tabs
 * and controls visibility of the timeout modals.
 *
 * Holding down shift & Y will show a debug panel.
 */
export const SessionTimeout: FC = () => {
	let timer: ReturnType<typeof setTimeout> = null;
	const dispatch = useDispatch();
	const [sessionStarted, setSessionStarted] = useState(
		parseInt(localStorage.getItem('auth.starttime'), 10)
	);
	const [sessionTimeout, setSessionTimeout] = useState(
		parseInt(localStorage.getItem('auth.refresh'), 10)
	);
	const [remaining, setRemaining] = useState(inactivityTimeout);
	const [elapsed, setElapsed] = useState(0);
	const [lastActive, setLastActive] = useState(+new Date());
	const [isOpen, setIsOpen] = useState(false);
	const [leader, setLeader] = useState(true);
	const [refreshingState, setRefreshingState] = useState(false);
	const sessionState = useUserSession();
	const tokenState = useTokenState();
	const currentTime = Date.now();

	const handleDebugger = useDebounce((event: KeyboardEvent) => {
		if (event.shiftKey && event.key === 'Y') {
			setIsOpen(true);
		}
	}, 500);

	const {
		getRemainingTime,
		getLastActiveTime,
		getElapsedTime,
		isIdle,
		isLeader,
		pause
	} = useIdleTimer({
		timeout: inactivityTimeout,
		crossTab: {
			emitOnAllTabs: true
		}
	});

	const resetSessionState = () => {
		setSessionStarted(parseInt(localStorage.getItem('auth.starttime'), 10));
		setSessionTimeout(parseInt(localStorage.getItem('auth.refresh'), 10));
		setRefreshingState(false);
		dispatch(resetTokenRefresh());
	};

	sessionBroadcast.onmessage = (messageEvent: { type: string }) => {
		switch (messageEvent.type) {
			case 'session_refresh_complete':
				resetSessionState();
				break;
			default:
		}
	};

	useEffect(() => {
		if (tokenState?.tokenRefreshComplete) {
			resetSessionState();
			sessionBroadcast.postMessage({ type: 'session_refresh_complete' });
		}
	}, [tokenState]);

	useEffect(() => {
		setRemaining(getRemainingTime());
		setLastActive(getLastActiveTime());
		setElapsed(getElapsedTime());

		if (timer) {
			clearInterval(timer);
			timer = null;
		}

		timer = setInterval(() => {
			setRemaining(getRemainingTime());
			setLastActive(e2eOverride ? Number(e2eOverride) : getLastActiveTime());
			setElapsed(getElapsedTime());
			setLeader(isLeader());
		}, 1000);

		document.addEventListener('keydown', handleDebugger);

		return () => {
			document.removeEventListener('keydown', handleDebugger);
		};
	}, []);

	if (
		!refreshingState &&
		!sessionState?.sessionHasEnded &&
		sessionState?.sessionWillTimeOut !== 'open' &&
		checkSessionHasExpiredAndIsActive(currentTime, sessionTimeout, lastActive)
	) {
		setRefreshingState(true);
		if (leader) {
			dispatch(callTokenRefresh());
		}
	}

	if (
		!refreshingState &&
		!sessionState?.sessionHasEnded &&
		sessionState?.sessionWillTimeOut !== 'open' &&
		checkSessionWillTimeOut(currentTime, lastActive)
	) {
		dispatch(setSessionWillTimeoutAction('open'));
	}

	if (
		!refreshingState &&
		!sessionState?.sessionHasEnded &&
		checkSessionHasTimedOut(currentTime, lastActive)
	) {
		pause();
		if (timer) {
			clearInterval(timer);
			timer = null;
		}
		dispatch(setSessionHasEndedAction(true));
		if (leader) {
			window.focus();
		}
	}

	return (
		<Slideout
			id={'session-debug'}
			size={'sm'}
			isOpen={isOpen}
			setIsOpen={setIsOpen}
		>
			<SlideoutContent>
				<H1 mt={0}>{t`Session Debug`}</H1>
				<TableWrapper>
					<table>
						<tbody>
							<tr>
								<th>{t`Idle Timeout`}</th>
								<td>{inactivityTimeout}ms</td>
							</tr>
							<tr>
								<th>{t`Time To Idle`}</th>
								<td>{remaining}</td>
							</tr>
							<tr>
								<th>{t`Time Elapsed Since Idle`}</th>
								<td>{elapsed}</td>
							</tr>
							<tr>
								<th>{t`Is Idle?`}</th>
								<td>{isIdle().toString()}</td>
							</tr>
							<tr>
								<th>{t`Is Leader Tab?`}</th>
								<td>{leader.toString()}</td>
							</tr>
							<tr>
								<th>{t`Last Active`}</th>
								<td>
									{DateTime.fromMillis(lastActive).toLocaleString(
										DateTime.DATETIME_FULL_WITH_SECONDS
									)}
								</td>
							</tr>
							<tr>
								<th>{t`Last Active (ms)`}</th>
								<td>{lastActive}</td>
							</tr>
							<tr>
								<th>{t`Session Start Time`}</th>
								<td>
									{DateTime.fromMillis(sessionStarted).toLocaleString(
										DateTime.DATETIME_FULL_WITH_SECONDS
									)}
								</td>
							</tr>
							<tr>
								<th>{t`Session Start Time (ms)`}</th>
								<td>{sessionStarted}</td>
							</tr>
							<tr>
								<th>{t`Session Timeout`}</th>
								<td>
									{DateTime.fromMillis(sessionTimeout).toLocaleString(
										DateTime.DATETIME_FULL_WITH_SECONDS
									)}
								</td>
							</tr>
							<tr>
								<th>{t`Session Timeout (ms)`}</th>
								<td>{sessionTimeout}</td>
							</tr>
							<tr>
								<th>{t`Current Time`}</th>
								<td>
									{DateTime.fromMillis(currentTime).toLocaleString(
										DateTime.DATETIME_FULL_WITH_SECONDS
									)}
								</td>
							</tr>
							<tr>
								<th>{t`Current Time (ms)`}</th>
								<td>{currentTime}</td>
							</tr>
							<tr>
								<th>{t`Inactivity Timeout`}</th>
								<td>
									{DateTime.fromMillis(lastActive + XMinutes).toLocaleString(
										DateTime.DATETIME_FULL_WITH_SECONDS
									)}
								</td>
							</tr>
							<tr>
								<th>{t`Inactivity Timeout (ms)`}</th>
								<td>{lastActive + XMinutes}</td>
							</tr>
						</tbody>
					</table>
				</TableWrapper>
			</SlideoutContent>
		</Slideout>
	);
};
