import { useState } from 'react';
import { MenuItem, MenuItemDef } from './MenuItem';
import { NavOverrides } from './NavOverrides';

type ItemsMap = { [id: string]: MenuItem };

function transformTree(items: MenuItem[]) {
	let firstPath: string;
	const result: MenuItem[] = [];

	for (const item of items) {
		// transform the item's children
		const [path, children] = transformTree(item.children);

		// update the first path if needed
		firstPath = firstPath || item.path || path;
		if (item.path || path || item.static) {
			// if the item has a path, add it to the menu
			result.push({
				...item,
				path: item.path || path,
				children
			});
		}
	}

	return [firstPath, result.sort((a, b) => a.priority - b.priority)] as [
		string,
		MenuItem[]
	];
}

/**
 * Rebuilds the menu from the items map.
 *
 * The menu is a tree of navigation items that contains only the items which have a path
 */
function buildMenu(items: ItemsMap) {
	const rootItems = Object.values(items).filter((i) => !i.parentId);
	const [, menu] = transformTree(rootItems);
	window.fx = {
		...window.fx,
		menu: () => {
			console.dir(menu);
		}
	};
	return menu;
}

function searchMenu(id: string, items: MenuItem[]): MenuItem {
	for (const item of items) {
		if (item.id === id) return item;
		const found = searchMenu(id, item.children);
		if (found) return found;
	}

	return null;
}

/**
 * Internal hook that provides the API used by the navigation provider
 */
export function useNavApi(overrides: NavOverrides) {
	const [{ items, menu }, setItems] = useState<{
		items: ItemsMap;
		menu: MenuItem[];
	}>({
		items: {},
		menu: []
	});

	function removeItems(ids: string[]) {
		for (const id of ids) {
			const item = items[id];
			if (item) {
				const parent = items[item.parentId];
				if (parent) {
					parent.children = parent.children.filter((c) => c.id !== id);
				}
				delete items[id];
			}
		}

		setItems({ items, menu: buildMenu(items) });
	}

	function addItems(newItems: MenuItemDef[]) {
		if (!newItems?.length) return;

		for (const newItem of newItems) {
			if (!newItem.id) throw Error(`Nav items need an id!`);
			const oldItem = items[newItem.id];
			if (
				oldItem &&
				oldItem.path &&
				newItem.path &&
				oldItem.path !== newItem.path
			) {
				// eslint-disable-next-line no-console
				console.warn(
					`A nav item with id [${newItem.id}] has already been declared with a different path.`
				);
			}

			if (!oldItem) {
				const item = {
					priority: 100,
					...newItem,
					...overrides.find(newItem.id),
					children: Object.values(items).filter((i) => i.parentId === newItem.id)
				};
				items[newItem.id] = item;
				const parent = items[item.parentId];
				if (parent) {
					parent.children.push(item);
				}
			}
		}

		setItems({ items, menu: buildMenu(items) });

		return () => removeItems(newItems.map((i) => i.id));
	}

	function subMenu(parentId?: string) {
		if (!parentId) return menu;

		return searchMenu(parentId, menu)?.children;
	}

	return {
		items,
		menu,
		addItems,
		removeItems,
		subMenu
	};
}
