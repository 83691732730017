import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';

interface LastCapability {
	org: string;
	capability: string;
}

export function useLastCapability() {
	const location = useLocation();
	const [data, setData] = useState<LastCapability>({
		org: null,
		capability: null
	});

	useEffect(() => {
		const route = location.pathname.split('/');
		if (route.length === 4) {
			const org = route[2];
			const capability = route[3];
			setData({
				org,
				capability
			});
		}
	}, [location.pathname]);

	return data;
}
