import { useAuthenticated } from '@flexera/auth.base';
import { useNavItems } from '@flexera/shell.navigation';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { t } from 'ttag';
import { ArrowLeft, Email, SettingsIcon } from '../assets';
import * as ids from './menuIds';

export function useNav() {
	const authenticated = useAuthenticated();
	const { addItems } = useNavItems();
	const location = useLocation();

	useEffect(() => {
		// Only show the settings when authenticated and on a settings page
		if (!authenticated || !location.pathname.startsWith('/settings/')) return;

		return addItems([
			{
				id: ids.BACK,
				path: '/', // The app root will determine which page to go back to
				icon: ArrowLeft,
				label: t`Back`,
				priority: 0
			},
			{
				id: ids.INVITATIONS,
				path: '/settings/invitations',
				urlMatch: /^\/settings\/invitations(\/|$|\?)/,
				icon: Email,
				label: t`Invitations`,
				priority: 1
			},
			{
				id: ids.API_CREDS,
				path: '/settings/api-credentials',
				urlMatch: /^\/settings\/api-credentials(\/|$|\?)/,
				icon: SettingsIcon,
				label: t`API Credentials`,
				priority: 2
			}
		]);
	}, [authenticated, location.pathname]);
}
