import React, { FC } from 'react';
import { H2, Flex, H5 } from '@flexera/ui.component-library';
import { t } from 'ttag';
import { CommunityWrapper, CommunityImage, CommunityBlurb } from './Styled';
import CommunitySVG from '../../assets/community.svg';

export const Community: FC = (): JSX.Element => {
	return (
		<CommunityWrapper>
			<H2 mt={0} mb={'xxs'} color={'blue70'}>
				{t`Connect with customers, experts, and thought leaders to find answers and guidance`}
			</H2>
			<Flex m={'auto'} alignItems={'flex-start'} pb={'2.5rem'}>
				<CommunityBlurb
					tabIndex={0}
					onClick={() => {
						const communityTab = window.open(
							'https://community.flexera.com/t5/SBOM-Insights/ct-p/SBOM-Insights',
							'_blank'
						);
						communityTab.focus();
					}}
				>
					<H5 color={'light'} mt={'0'}>
						{t`JOIN US TODAY`}
					</H5>
					<H2 color={'light'} mt={'0'} mb={'0'}>
						{t`Visit the community`}
					</H2>
				</CommunityBlurb>
				<CommunityImage>
					<CommunitySVG />
				</CommunityImage>
			</Flex>
		</CommunityWrapper>
	);
};
