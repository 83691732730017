import styled from 'styled-components';

export const CustomAppSwitcherItem = styled.li`
	border-bottom: 1px solid;
	border-color: ${props => props.theme.colors.grey[2]};
	min-height: 64px;
	display: block;
	button {
		width: 100%;
		border: 0;
		background: ${props => props.theme.colors.light};
		text-align: left;
		padding-bottom: 10px;
		padding-top: 11px;
		padding-left: 16px;
		padding-right: 16px;
		display: flex;
		justify-content: center;
		flex-direction: column;
		text-decoration: none;
		background: ${props => props.theme.colors.light};
		outline: none;
		text-transform: none;

		&:hover {
			cursor: pointer;
			background: ${props => props.theme.colors.grey[0]};
		}
		&:focus {
			box-shadow: none;
		}

		> h3 {
			margin-bottom: 0.2rem;
			margin-top: 0;
			line-height: ${props => props.theme.lineHeights.md};
			color: ${props => props.theme.colors.grey[6]};
			font-size: ${props => props.theme.fontSizes.md};
			font-weight: ${props => props.theme.fontWeights.bold};
			font-family: ${props => props.theme.fonts.secondary};
			letter-spacing: ${props => props.theme.letterSpacings.lg};
		}

		> p {
			margin-bottom: 0;
			margin-top: 0;
			line-height: ${props => props.theme.lineHeights.lg};
			color: ${props => props.theme.colors.grey[6]};
			font-size: ${props => props.theme.fontSizes.xs};
			font-weight: ${props => props.theme.fontSizes.regular};
			font-family: ${props => props.theme.fonts.primary};
		}
	}
`;
