import { IModule, createStore } from 'redux-dynamic-modules';
import { getSagaExtension } from 'redux-dynamic-modules-saga';
import { authSaga } from '@flexera/lib.auth';
import { reducer } from './reducer';
import { State } from './State';

export const IAMmodule = () =>
	({
		id: 'iamAuth',
		reducerMap: {
			iamAuth: reducer
		},
		initialActions: [],
		sagas: [authSaga]
	} as IModule<State>);

export const store = createStore(
	{
		extensions: [getSagaExtension()]
	},
	IAMmodule()
);
