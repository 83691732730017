import { t } from 'ttag';

export function loadingItem(id: string, parentId?: string) {
	return {
		id,
		parentId,
		label: t`loading...`, // needed at the moment because the nav component expects a label
		priority: 50,
		path: 'loading'
	};
}
