import { RedirectToLogin, useAuthenticated } from '@flexera/auth.base';
import React, { FC, useContext } from 'react';
import { Switch } from 'react-router';
import { Route as RouterRoute } from 'react-router-dom';
import { RouterContext } from './RouterContext';

/**
 * This component renders the child routes for a given route id
 */
export const ChildRoutes: FC<{
	routeId?: string;
}> = ({ routeId }) => {
	const context = useContext(RouterContext);
	const authenticated = useAuthenticated();
	const routes = routeId ? context.routesById[routeId].children : context.routes;

	return (
		<Switch>
			{routes
				.sort((a, b) => a.priority - b.priority)
				.map((route) => (
					<RouterRoute
						path={context.fullPath(route.id)}
						exact={route.exact && route.children.length === 0}
						key={route.id}
					>
						{route.public || authenticated ? (
							<route.component />
						) : (
							<RedirectToLogin
								returnUrl={`${window.location.pathname}${window.location.search}${window.location.hash}`}
							/>
						)}
					</RouterRoute>
				))}
		</Switch>
	);
};
