/* eslint-disable no-restricted-syntax */
import React, { FC, useState } from 'react';
import { Route, RouteDef } from './Route';
import { RouterContext } from './RouterContext';
import { RouterContextValue } from './RouterContextValue';

const routes: Route[] = [];
const routesById: { [id: string]: Route } = {};
const routesByParent: { [parentId: string]: Route[] } = {};

function addRoutes(newRoutes: RouteDef[]) {
	const added: Route[] = [];
	newRoutes.forEach(route => {
		const { id, parentId } = route;

		if (routesById[id]) {
			throw new Error(`A route with id [${id}] has already been declared...`);
		}

		if (!routesByParent[id]) {
			routesByParent[id] = [];
		}

		routesById[id] = {
			priority: 100,
			exact: true,
			...route,
			children: routesByParent[id]
		};
		added.push(routesById[id]);

		if (parentId) {
			if (!routesByParent[parentId]) {
				routesByParent[parentId] = [routesById[id]];
				if (routesById[parentId]) {
					routesById[parentId].children = routesByParent[parentId];
				}
			} else {
				routesByParent[parentId].push(routesById[id]);
			}
		} else {
			routes.push(routesById[id]);
		}
	});

	return added;
}

function removeRoutes(ids: string[]) {
	for (const id of ids) {
		const route = routesById[id];
		if (route) {
			if (route.parentId) {
				const arr = routesByParent[route.parentId];
				arr.splice(arr.indexOf(route), 1);
			} else {
				routes.splice(routes.indexOf(route), 1);
			}
			delete routesById[id];
		}
	}
}

function removeExactRoutes(toRemove: Route[]) {
	for (const route of toRemove) {
		if (routesById[route.id]) delete routesById[route.id];

		if (routes.includes(route)) routes.splice(routes.indexOf(route), 1);

		const siblings = routesByParent[route.parentId];
		if (siblings?.includes(route)) siblings.splice(siblings.indexOf(route), 1);
	}
}

function fullPath(id: string) {
	let route = routesById[id];
	if (!route) {
		throw new Error(`Could not find route with id [${id}]`);
	}

	const parts = [];
	do {
		parts.unshift(route.path);
		route = routesById[route.parentId];
	} while (route);

	// TODO: tweak this code to avoid getting path for an incomplete branch
	// if (route.parentId) {
	// 	throw new Error(`The route with [${id}] is not in a complete tree branch`);
	// }

	return parts.join('');
}

export const RouterProvider: FC = ({ children }) => {
	const [state, setState] = useState<RouterContextValue>({
		routes,
		routesById,
		routesByParent,
		fullPath,
		addRoutes: newRoutes => {
			const added = addRoutes(newRoutes);
			setState({ ...state });
			return () => {
				removeExactRoutes(added);
				setState({ ...state });
			};
		},
		removeRoutes: ids => {
			removeRoutes(ids);
			setState({ ...state });
		}
	});
	return (
		<RouterContext.Provider value={state}>{children}</RouterContext.Provider>
	);
};
