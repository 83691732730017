import React, { FC, useEffect, useState } from 'react';
import { withModules } from '@flexera/lib.state-management';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { InfoBox } from '@flexera/ui.component-library';
import { Loader } from '@flexera/lib.loader';
import {
	GOODDATA_DOMAIN,
	OKTA_DOMAIN,
	OKTA_GD_APP_RELAY
} from '@flexera/lib.api';
import { QueryOptions } from '../service/GoodData';
import { useGooddataEmbed } from '../hooks';
import { goodDataEmbedModule } from '../module';
import { Context, Status } from '../state';
import { GoodDataClickHandlers } from './GoodDataClickHandlers';
import {
	getKPIDashboards,
	getDashboardURIAction,
	getWorkspaceURIAction,
	registerGoodDataEmbedAction
} from '../actions';

interface Props {
	capability: string;
	dataProduct?: string;
	dashboardIdentifier?: string;
	dashboardType: string;
	defaultToFirstDashboard?: boolean;
	id: string;
	render: (
		projectId: string,
		dashboardId?: string,
		kpiDashboards?: any[],
		hasNoDashboards?: boolean
	) => JSX.Element;
	queryOptions?: QueryOptions;
	skipDashboard?: boolean;
	hasOverride?: {
		workspaceId?: string;
		dashboardId?: string;
	};
}

export const GoodDataBootstrap: FC<Props> = withModules(
	[goodDataEmbedModule()],
	(props: Props) => {
		const dispatch = useDispatch();
		const { org_id: orgId } = useParams();
		const [authFrameSrc, setAuthFrameSrc] = useState<string>(null);
		const [frameReady, setFrameReady] = useState<boolean>(false);
		const {
			status,
			statusMsg,
			context,
			workspaceURI,
			dashboardId,
			projectId,
			kpiDashboards
		} = useGooddataEmbed(props.id);

		// Kickoff the bootstrap process
		useEffect(() => {
			dispatch(registerGoodDataEmbedAction(props.id));
		}, []);

		// Get workspace URI from the flexera API
		useEffect(() => {
			if (status === Status.Init && context === null) {
				dispatch(
					getWorkspaceURIAction(orgId, props.id, props.capability, props.dataProduct)
				);
			}
		}, [status, context]);

		// Authenticate Gooddata through Okta
		useEffect(() => {
			if (
				status === Status.Success &&
				context === Context.Workspace &&
				workspaceURI
			) {
				const explicitWorkspaceURI = props.hasOverride
					? props.hasOverride.workspaceId
					: workspaceURI;
				const oktaGdAppRelay = `${OKTA_DOMAIN}/${OKTA_GD_APP_RELAY}`;
				const gdDashboard = `https://${GOODDATA_DOMAIN}/dashboard.html#project=${explicitWorkspaceURI}`;
				const relayState = encodeURIComponent(gdDashboard);
				setAuthFrameSrc(`${oktaGdAppRelay}?RelayState=${relayState}`);
			}
		}, [orgId, status, workspaceURI]);

		// Authentication was successful, now get dashboard Id
		useEffect(() => {
			if (frameReady && projectId && !props.skipDashboard) {
				dispatch(
					getDashboardURIAction(
						props.id,
						projectId,
						props.dashboardIdentifier,
						props.dashboardType,
						props.queryOptions
					)
				);
			}
		}, [frameReady, projectId]);

		// Everything went right!
		if (props.dashboardType === 'PixelPerfect' && workspaceURI && dashboardId) {
			return (
				<GoodDataClickHandlers>
					{props.render(workspaceURI, dashboardId)}
				</GoodDataClickHandlers>
			);
		}

		// Everything went right!
		if (props.dashboardType === 'KPI' && projectId && dashboardId) {
			return (
				<GoodDataClickHandlers>
					{props.render(projectId, dashboardId)}
				</GoodDataClickHandlers>
			);
		}

		// Everything went right!
		if (props.dashboardType === 'CustomKPI' && projectId && frameReady) {
			const explicitProjectId = props.hasOverride
				? props.hasOverride.workspaceId
				: projectId;
			if (status !== Status.KPISuccess && !kpiDashboards.length) {
				dispatch(getKPIDashboards(props.id, explicitProjectId));
			}
			if (status === Status.KPISuccess) {
				return (
					<GoodDataClickHandlers>
						{props.render(
							explicitProjectId,
							null,
							kpiDashboards,
							kpiDashboards.length === 0
						)}
					</GoodDataClickHandlers>
				);
			}
		}

		// Display a range of errors from any failed step.
		if (statusMsg !== '' && status === Status.Error) {
			return <InfoBox variant={'error'}>{statusMsg}</InfoBox>;
		}

		// Use iframe with authenticate with Okta and Gooddata
		return (
			<>
				<Loader isFullScreen={false} />
				{authFrameSrc && (
					<>
						<iframe
							title={'auth'}
							id={'gooddata-saml-auth'}
							onLoad={() => setFrameReady(true)}
							src={authFrameSrc}
							frameBorder={'0'}
							width={'0'}
							height={'0'}
						/>
					</>
				)}
			</>
		);
	}
);
