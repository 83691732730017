export enum MenuIds {
	Administration = 'apps.flexera-one/v1/admin',
	AppBroker = 'apps.flexera-one/v1/app-broker',
	CloudResourceBrowser = 'apps.flexera-one/v1/crb',
	ITVisibility = 'apps.flexera-one/v1/itv',
	Optima = 'apps.flexera-one/v1/optima',
	Policies = 'apps.flexera-one/v1/policies',
	SaaS = 'apps.flexera-one/v1/fsm',
	UnifiedSpend = 'apps.flexera-one/v1/usa',
	ItAssets = 'apps.flexera-one/v1/itassets',
	Settings = 'apps.flexera-one/v1/settings',
	PortalManagement = 'apps.flexera-one/v1/app-broker/portal',
	UDI = 'apps.flexera-one/v1/itv/udi',
	SaaSApps = 'apps.flexera-one/v1/fsm/apps',
	SaaSUsers = 'apps.flexera-one/v1/fsm/users',
	SaaSSettings = 'apps.flexera-one/v1/fsm/settings'
}
