import { MenuItemOverride } from './MenuItem';

/**
 * Contains a set of overrides to be applied to menu items when they are registered.
 *
 * This allows a NavProvider consumer to manipulate the menu tree without making assumptions
 * as to whether they will be present or not.
 *
 * The order in which overrides are declared is significant as the first match will be used.
 */
export class NavOverrides {
	private map: Map<string | RegExp, MenuItemOverride>;

	constructor(value: Iterable<[string | RegExp, MenuItemOverride]> = []) {
		this.map = new Map(value);
	}

	/**
	 * Returns the override matching a given menu item if present.
	 * @param id The id of the menu item
	 */
	find(id: string) {
		for (const [key, value] of this.map) {
			if (key === id) {
				return value;
			}
			if (typeof key !== 'string' && key.test(id)) {
				return value;
			}
		}
	}
}
