import styled from 'styled-components';
import { BaseTheme } from '@flexera/ui.component-library';
import { svgStyle, linkStyle, activeBackground } from '../constants';

interface SubListProps {
	isPinned?: boolean;
}

export const SubList = styled.ul<SubListProps>`
	display: block;
	list-style: none;
	background: ${BaseTheme.colors.transparent};
	color: ${BaseTheme.colors.secondary};
	overflow-y: auto;
	padding-left: 0px;
	height: 100%;
	cursor: default;
	padding-top: ${BaseTheme.space.sm};

	&.active-search-style {
		padding: ${BaseTheme.space.xs} 0px;
		& > * {
			${linkStyle}
		}
		& :last-child {
			&::after {
				display: none;
			}
		}
	}

	:first-child {
		margin-top: 0px;
	}

	& :last-child {
		&::after {
			display: flex;
			flex: 1 1 0%;
			min-height: 2.5rem;
			background: transparent;
		}
	}
`;

export const CategoryList = styled.li`
	width: 100%;
	list-style: none;
	margin: unset;
	padding-bottom: 10px;
	&::after {
		display: block;
		margin: 6px auto 0px;
		content: '';
		background: #3b4050;
		height: 1px;
		width: calc(100% - 2rem);
	}
`;

export const CategoryListDescription = styled.span`
	display: flex;
	font-size: ${BaseTheme.fontSizes.xxs};
	font-family: 'Source Sans Pro Bold', sans-serif;
	font-weight: ${BaseTheme.fontWeights.semiBold};
	letter-spacing: ${BaseTheme.letterSpacings.sm}px;
	align-items: center;
	height: 32px;
	width: calc(100% - 1rem);
	min-width: 220px;
	padding-left: ${BaseTheme.space.sm};
	line-height: ${BaseTheme.lineHeights.sm}px;
	text-transform: uppercase;

	& > span.matched-search {
		color: ${BaseTheme.colors.primaryActive};
		font-weight: ${BaseTheme.fontWeights.semiBold};
	}
`;

export const GrandChildList = styled.ul`
	margin: unset;
	padding: unset;
`;

export const FavoriteableListItem = styled.li`
	display: grid;
	grid-template-columns: auto 50px;
	align-items: center;
	flex-wrap: nowrap;
	cursor: pointer;
	font-size: ${BaseTheme.fontSizes.xs};
	position: relative;
	min-height: ${BaseTheme.space.lg};
	min-width: 230px;
	line-height: 18px;

	a {
		margin-right: 0px;
		margin-left: ${BaseTheme.space.md};
		padding: 6px ${BaseTheme.space.xxs};
		white-space: pre-wrap;
	}

	button.favorite-not-active {
		grid-column: 2;
		${svgStyle}
		opacity: 0;
	}

	& > a > span.matched-search {
		color: ${BaseTheme.colors.primaryActive};
		font-weight: ${BaseTheme.fontWeights.semiBold};
	}

	&:hover,
	&:focus-within {
		background: ${activeBackground};
		button.favorite-not-active {
			opacity: 1;
		}
	}
`;
