import React from 'react';
import styled from 'styled-components';
import { BaseTheme } from '@flexera/ui.component-library';

type NavigationGenericNestedObject = {
	[key: string]: string | number | NavigationGenericNestedObject;
};

interface NavigationTheme extends NavigationGenericNestedObject {
	background: {
		active: string;
		hover: string;
		primary: string;
		secondary: string;
		tertiary: string;
	};
	fonts: {
		primary: string;
		primaryBold: string;
	};
	width: {
		panel: number | string;
	};
}

const AdditionsToTheme: NavigationTheme = {
	background: {
		primary: '#0A1024',
		secondary: '#080D1D',
		tertiary: '#23283A',
		active: 'green',
		hover: '#3B4050'
	},
	width: {
		panel: 240
	},
	fonts: {
		primary: 'Source Sans Pro, sans-serif',
		primaryBold: 'Source Sans Pro Bold, sans-serif'
	}
};

const theme = {
	...BaseTheme,
	...AdditionsToTheme
};

const dividerStyles = (provided: any, props: any) => ({
	height: '1px',
	margin: `${theme.space.xs} 0`,
	padding: `0 ${theme.space.sm}`,
	[`div`]: {
		background: props.theme.background.tertiary,
		height: '1px'
	}
});

const DividerStyled = styled.div((props) => dividerStyles({}, props));

/*
	Both of packages/apps/flexera-one-test-app/src/app-design/SetupNavMenu.tsx
	and packages/apps/flexera-one/src/app-design/v2/SetupNavMenu.tsx
	had dependency on Divider from @flexera/navigation.components,
	in order to remove @flexera/navigation.components Divider was moved 
	to the closest common dependency (@flexera/shell.navigation)
*/

export const Divider = () => (
	<DividerStyled>
		<div />
	</DividerStyled>
);
