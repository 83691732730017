import React, { FC, useState, useEffect } from 'react';
import {
	Icon,
	IconMdArrowLeft,
	IconMdArrowRight,
	Flex
} from '@flexera/ui.component-library';
import { t } from 'ttag';
import { useDebounce } from '@flexera/shell.splash';
import {
	scrollCarousel,
	isCarouselOverflown,
	Actions,
	IconButton
} from '@flexera/shell.landing-footer';
import { SectionTitle } from '../../Styled';

export const GetStartedHeading: FC = () => {
	const [showGetStartedActions, setShowGetStartedActions] = useState(false);
	const [getStartedCarouselPosition, setGetStartedCarouselPosition] = useState({
		isLeft: true,
		isRight: false
	});

	const setActionsVisibility = useDebounce(() => {
		setShowGetStartedActions(isCarouselOverflown('get-started-carousel'));
	}, 250);

	useEffect(() => {
		setActionsVisibility();
		window.addEventListener('resize', setActionsVisibility);
		return () => window.removeEventListener('resize', setActionsVisibility);
	}, []);

	return (
		<Flex justifyContent={'space-between'} alignItems={'flex-end'}>
			<SectionTitle>{t`Get Started`}</SectionTitle>
			{showGetStartedActions && (
				<Actions>
					<IconButton
						id={'scroll-left'}
						title={'Scroll Left'}
						p={'xxs'}
						type={'button'}
						zIndex={1}
						variant={'secondary'}
						onClick={() =>
							scrollCarousel('get-started-carousel', 'left', (data) => {
								setGetStartedCarouselPosition(data);
							})
						}
						disabled={getStartedCarouselPosition.isLeft}
					>
						<Icon src={IconMdArrowLeft} />
					</IconButton>
					<IconButton
						id={'scroll-right'}
						title={'Scroll right'}
						p={'xxs'}
						type={'button'}
						zIndex={1}
						variant={'secondary'}
						onClick={() =>
							scrollCarousel('get-started-carousel', 'right', (data) => {
								setGetStartedCarouselPosition(data);
							})
						}
						disabled={getStartedCarouselPosition.isRight}
					>
						<Icon src={IconMdArrowRight} />
					</IconButton>
				</Actions>
			)}
		</Flex>
	);
};
