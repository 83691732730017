import React, { FC, useState, useEffect } from 'react';
import { t } from 'ttag';
import { InfoBox, Icon, IconMdHome } from '@flexera/ui.component-library';
import {
	GoodDataEmbedStyled,
	GoodDataEmbedWrapper
} from './GoodDataEmbedStyles';
import {
	GET_GD_EVENT_REASON,
	KPI_DASHBOARD_TEMPLATE,
	PP_DASHBOARD_TEMPLATE,
	CUSTOM_DASHBOARD_TEMPLATE,
	CUSTOM_NEW_DASHBOARD_TEMPLATE,
	CUSTOM_NEW_DASHBOARD_NOCLIENT_TEMPLATE
} from '../service/Constants';
import { IGoodDataEvent } from '../service/Interfaces';
import { isValidGDMessageHost } from '../service/GoodData';

interface UserFeedback {
	variant: 'success' | 'info' | 'warning' | 'error';
	message: string;
}

interface Props {
	dashboardType: string;
	baseUrl: string;
	dashboardId?: string;
	workspaceUri?: string;
	defaultHeight?: number;
	id?: string;
	projectId?: string;
	responsiveHeight?: boolean;
	onMessageReceived?: (event: any) => void;
	query?: { [key: string]: string };
	hideHome?: boolean;
	currentDashboardId?: string;
	overrideHeight?: string;
	dataProductId?: string;
	clientId?: string;
}

export const GoodDataEmbed: FC<Props> = ({
	dashboardType,
	dashboardId,
	workspaceUri,
	defaultHeight = 600,
	projectId,
	id = 'gooddata',
	onMessageReceived,
	query,
	hideHome,
	currentDashboardId,
	overrideHeight,
	dataProductId,
	clientId
}) => {
	let src = '';
	const [frameKey, setFrameKey] = useState(Math.random());
	const [dynamicSource, setDynamicSource] = useState('');

	const [userFeedback, setUserFeedback] = useState<UserFeedback>({
		variant: 'error',
		message: ''
	});

	const onGoodDataMessage = ({ origin, data }: IGoodDataEvent) => {
		if (!isValidGDMessageHost(origin)) return;
		if (
			dashboardType === 'KPI' ||
			dashboardType === 'CustomKPI' ||
			dashboardType === 'NewKPIDashboard'
		) {
			if (data.gdc) {
				const { height } = data.gdc.event.data;
				switch (data.gdc.event.name) {
					case 'resized':
						if (height > defaultHeight && !overrideHeight) {
							document.getElementById(id).style.height = `${height}px`;
						}
						break;
					default:
						break;
				}
				if (typeof onMessageReceived === 'function') {
					return onMessageReceived(data);
				}
			}
		}
		if (dashboardType === 'PixelPerfect') {
			try {
				let message = JSON.parse(data);
				if (message.gdc) {
					message = message.gdc;
					const reason = GET_GD_EVENT_REASON(message);
					switch (message.type) {
						case 'app.ok':
							if (message.name === 'ui.frameinfo') {
								document.getElementById(id).style.height = `${message.data.height}px`;
							}
							if (message.name === 'project.info.no.permission') {
								const noPermission = t`You do not have to the correct privileges to view this dashboard. Please contact your Flexera administrator to request the correct privileges.`;
								setUserFeedback({
									variant: 'error',
									message: noPermission
								});
							}
							break;
						case 'app.down':
							setUserFeedback({
								variant: 'warning',
								message: reason
							});
							break;
						default:
					}
					// PP Component Related Listeners
					return onMessageReceived(message);
				}
			} catch (error) {
				console.warn(error);
			}
		}
	};

	useEffect(() => {
		window.addEventListener('message', onGoodDataMessage, false);
		return () => window.removeEventListener('message', onGoodDataMessage);
	}, []);

	useEffect(() => {
		if (currentDashboardId) {
			const newURL =
				dashboardType === 'KPI'
					? KPI_DASHBOARD_TEMPLATE(projectId, currentDashboardId)
					: CUSTOM_DASHBOARD_TEMPLATE(dataProductId, clientId, currentDashboardId);
			setDynamicSource(newURL);
		}
	}, [currentDashboardId]);

	const refreshFrame = () => {
		setFrameKey(Math.random());
	};

	if (dashboardType === 'KPI') {
		src = KPI_DASHBOARD_TEMPLATE(projectId, dashboardId);
	}

	if (dashboardType === 'PixelPerfect') {
		src = PP_DASHBOARD_TEMPLATE(workspaceUri, dashboardId, { query });
	}

	if (dashboardType === 'NewKPIDashboard') {
		src = CUSTOM_NEW_DASHBOARD_TEMPLATE(dataProductId, clientId);
	}

	if (dashboardType === 'NewKPIDashboardNoClient') {
		src = CUSTOM_NEW_DASHBOARD_NOCLIENT_TEMPLATE(projectId);
	}

	if (userFeedback.message !== '') {
		return <InfoBox variant={'error'}>{userFeedback}</InfoBox>;
	}

	return (
		<>
			<GoodDataEmbedWrapper>
				{dashboardType === 'PixelPerfect' && !hideHome && (
					<Icon
						src={IconMdHome}
						m={'sm'}
						onClick={refreshFrame}
						cursor={'pointer'}
						color={'#00559f'}
					/>
				)}

				<GoodDataEmbedStyled
					key={frameKey}
					style={{
						height: overrideHeight || `${defaultHeight}px`,
						padding:
							dashboardType === 'PixelPerfect'
								? `${hideHome ? '24px 0 10px 24px' : '0 0 10px'}`
								: '0'
					}}
					id={id}
					src={dynamicSource || src}
				/>
			</GoodDataEmbedWrapper>
		</>
	);
};
