import React, { FC } from 'react';
import { DynamicModuleLoader } from 'redux-dynamic-modules';
import { IAMmodule } from '../module';
import { AuthProvider } from './AuthProvider';

export const IamAuth: FC = ({ children }) => (
	<DynamicModuleLoader modules={[IAMmodule()]}>
		<AuthProvider>{children}</AuthProvider>
	</DynamicModuleLoader>
);
