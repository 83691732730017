import { EnforceSso, IamAuth, InactivityTimeout } from '@flexera/auth.iam';
import {
	FlagsProvider,
	useExplorerNav
} from '@flexera/flexera-one.feature-flags';
import {
	setFNMSCookieRefreshUrl,
	setFNMSHostOverride
} from '@flexera/fnms.bootstrap';
import { FlexeraFonts } from '@flexera/lib.fonts';
import { ReportsProvider } from '@flexera/sbom-reports';
import { renderShell } from '@flexera/shell.main';
import { OrgsProvider } from '@flexera/shell.orgs';
import { OrgRoutes } from '@flexera/shell.orgs-routes';
import { BaseTheme } from '@flexera/ui.component-library';
import React from 'react';
import { InitV1App } from './app-design/v1/InitV1App';
import { InitApp } from './app-design/v2/InitApp';
import './index.branch.html';
import './terraform-config';

const Layout = () => {
	const explorerLayout = useExplorerNav();
	return explorerLayout ? <InitApp /> : <InitV1App />;
};

// These function calls allow for FNMS host override values to
// be set via query parameters from the login screen
setFNMSCookieRefreshUrl();
setFNMSHostOverride();

renderShell(
	BaseTheme,
	<>
		<FlexeraFonts />
		<IamAuth>
			<OrgsProvider>
				<FlagsProvider>
					<ReportsProvider>
						<InactivityTimeout />
						<EnforceSso />
						<OrgRoutes />
						<Layout />
					</ReportsProvider>
				</FlagsProvider>
			</OrgsProvider>
		</IamAuth>
	</>
);
