import React, { FC } from 'react';
import { NavContext } from './NavContext';
import { NavOverrides } from './NavOverrides';
import { useNavApi } from './useNavApi';

export const NavProvider: FC<{
	overrides?: NavOverrides;
}> = ({ children, overrides }) => {
	const navApi = useNavApi(overrides || new NavOverrides());
	return <NavContext.Provider value={navApi}>{children}</NavContext.Provider>;
};
