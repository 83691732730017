import { FavoriteItem } from '../types';
import { storeItem } from '@flexera/lib.setting-storage';

export const updateFavorites = (
	orgId: string,
	favoriteStore: FavoriteItem[],
	newFavoriteItem?: FavoriteItem
) => {
	if (orgId) {
		const newData = newFavoriteItem
			? [...favoriteStore, newFavoriteItem]
			: favoriteStore;

		storeItem({ key: 'favorite-menu', data: newData, orgId });
	}
};
