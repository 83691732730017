import styled from 'styled-components';

export const GridLayout = styled.div`
	width: 100%;
	height: 100%;
	display: grid;
	grid-template-columns: auto 1fr;
	grid-template-rows: 64px 1fr;
	gap: 0px 0px;
	grid-template-areas:
		'Navigation Header'
		'Navigation Content';
	overflow: hidden;
	& .gov-styles-wrapper .fusion-layout {
		height: calc(100vh - 46px);
	}
`;
