export enum NavigationActions {
	onPrimaryItemHover = 'onPrimaryItemHover',
	setCurrentAppAndActiveId = 'setCurrentAppAndActiveId',
	closeNavAndFlyout = 'closeNavAndFlyout',
	expandNavAndUnpinFlyout = 'expandNavAndUnpinFlyout',
	openFlyoutAndSetChildren = 'openFlyoutAndSetChildren',
	openSearch = 'openSearch',
	unpinFlyout = 'unpinFlyout',
	resetSearch = 'resetSearch',
	setActiveRouteId = 'setActiveRouteId',
	setCurrentAppId = 'setCurrentAppId',
	setCurrentChildren = 'setCurrentChildren',
	setFilteredChildren = 'setFilteredChildren',
	setIsNavExpanded = 'setIsNavExpanded',
	setIsFlyoutPinned = 'setIsFlyoutPinned',
	setIsFlyoutOpen = 'setIsFlyoutOpen',
	setInactiveStyles = 'setInactiveStyles',
	setIsSearchActive = 'setIsSearchActive',
	setSearch = 'setSearch',
	setFocusTrap = 'setFocusTrap',
	setFavoriteMenuItems = 'setFavoriteMenuItems',
	favoriteIconClicked = 'favoriteIconClicked',
	setRecentSearches = 'setRecentSearches'
}
