export enum Status {
	Loading = 'Loading',
	Success = 'Success',
	KPISuccess = 'KPISuccess',
	Error = 'Error',
	Init = 'Init'
}

export enum Context {
	Workspace = 'Workspace',
	Dashboard = 'Dashboard'
}

export interface Embed {
	context: Context | null;
	dashboardId?: string;
	dashboardURI?: string;
	projectId?: string;
	status?: Status;
	statusMsg?: string;
	workspaceURI?: string | null;
	kpiDashboards?: [];
}

export interface Embeds {
	[key: string]: Embed;
}

export interface GoodDataEmbedState {
	embeds: Embeds;
}

export interface State {
	gooddataEmbedState: GoodDataEmbedState;
}
