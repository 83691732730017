/* eslint-disable array-callback-return */
import React, { FC, useRef, useEffect, useState } from 'react';
import { Loader } from '@flexera/lib.loader';
import { useUserProfile } from '@flexera/auth.base';
import { PageLayout } from '@flexera/lib.layout';
import { useCurrentOrg } from '@flexera/shell.orgs';
import { Permissions, usePermissionSummary } from '@flexera/lib.permissions';
import { retrieveItem } from '@flexera/lib.setting-storage';
import { LandingFooter } from '@flexera/shell.landing-footer';
import { useRect } from '@flexera/shell.splash';
import { BaseTheme } from '@flexera/ui.component-library';
import {
	CookieModal,
	hasImplicitDeclineForSession
} from '@flexera/settings.main';
import { Wrapper, Welcome, SectionFooter, LinkContainer } from './Styled';
import {
	GetStartedHeading,
	GetStartedCarousel,
	Community,
	HelpfulLinks,
	DocumentationLibrary
} from './components';

export const LandingPage: FC = () => {
	const org = useCurrentOrg();
	const user = useUserProfile();
	const wrapperRef = useRef();
	const wrapperRect = useRect(wrapperRef);

	const [openCookieModal, setOpenCookieModal] = useState(false);
	const permsEnabled = true; // temp flag: for ux feedback

	const [perms, loadingPerms] = usePermissionSummary(
		`/orgs/${org.id}`,
		Permissions.hasITVisibility,
		Permissions.hasFNMS,
		Permissions.hasSaasManager,
		Permissions.hasOptima,
		Permissions.hasAppBroker,
		Permissions.hasSpendAnalytics,
		Permissions.hasPolicies,
		Permissions.hasIAM,
		Permissions.canSeeIdentityProviders,
		Permissions.canSeeOrgInvitations,
		Permissions.canSeeBeaconsPage,
		Permissions.canSeeOptimaBillingConfig,
		Permissions.canShowSaasManagedProducts,
		Permissions.canSeePolicyCatalog,
		Permissions.hasSbom
	);

	useEffect(() => {
		const fetchStoredCookieSettings = async () => {
			if (!hasImplicitDeclineForSession() && openCookieModal !== undefined) {
				const storedCookieSettings = await retrieveItem({ key: 'cookieSettings' });
				if (!storedCookieSettings) {
					setOpenCookieModal(true);
				}
			}
		};
		fetchStoredCookieSettings();
	}, []);

	return (
		<PageLayout padding={'0'} canScroll background={`${BaseTheme.colors.light}`}>
			<Wrapper ref={wrapperRef}>
				{loadingPerms ? (
					<Loader />
				) : (
					<>
						<Welcome>Hello {user.firstName},</Welcome>
						{/* Get Started */}
						<GetStartedHeading />
						<GetStartedCarousel permsEnabled={permsEnabled} perms={perms} />

						<SectionFooter>
							<LinkContainer>
								{/* Documentation */}
								<DocumentationLibrary permsEnabled={permsEnabled} perms={perms} />
								{/* Helpful Links */}
								<HelpfulLinks />
							</LinkContainer>
							{/* Community */}
							<Community />
						</SectionFooter>
						{/* Video Footer */}
						<LandingFooter boundingRect={wrapperRect} />

						{openCookieModal && <CookieModal open />}
					</>
				)}
			</Wrapper>
		</PageLayout>
	);
};
