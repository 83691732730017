import React, { FC } from 'react';
import { Link } from '@flexera/ui.component-library';
import { t } from 'ttag';
import { SubTitle } from '../../Styled';
import { helpfulLinks } from '../../data';
import { HelpfulLinkWapper } from './Styled';

export const HelpfulLinks: FC = (): JSX.Element => {
	return (
		<HelpfulLinkWapper
			flexDirection={'column'}
			pl={[0, 0, '1.75rem']}
			borderLeft={'sm'}
			borderColor={'infoSecondary'}
			minWidth={'228px'}
		>
			<SubTitle>{t`Helpful Links`}</SubTitle>
			{helpfulLinks.map((item) => {
				return (
					<Link
						key={item.id}
						title={item.title}
						id={item.title.toLowerCase().split(' ').join('-')}
						href={item.link}
						target={'_blank'}
						showExtIcon={false}
						mb={'xxs'}
						lineHeight={'lg'}
					>
						{item.title}
					</Link>
				);
			})}
		</HelpfulLinkWapper>
	);
};
