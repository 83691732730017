import { t } from 'ttag';

export const documentationItems = [
	{
		id: 0,
		title: t`SBOM Insights Quick Start Guide`,
		link:
			'https://docs.revenera.com/sbom_insights/Content/helplibrary/tile_gs.htm',
		icon: 'IconMdITV',
		hasPermissionCheck: 'hasSbom'
	},
	{
		id: 1,
		title: t`Using SBOM Insights`,
		link:
			'https://docs.revenera.com/sbom_insights/Content/helplibrary/Using_SBOM_Insights.htm#usingsbom',
		icon: 'IconMdITV',
		hasPermissionCheck: 'hasSbom'
	},
	{
		id: 2,
		title: t`SBOM Insights API`,
		link:
			'https://docs.revenera.com/sbom_insights/Content/helplibrary/tile_api.htm#sbom_insights_api',
		icon: 'IconMdITManagement',
		hasPermissionCheck: 'hasSbom'
	},
	{
		id: 3,
		title: t`SBOM Insights Administration`,
		link:
			'https://docs.revenera.com/sbom_insights/Content/helplibrary/SBOM_Insights_Administration.htm#sbom_insights_administration',
		icon: 'IconFxBeacon',
		hasPermissionCheck: 'hasSbom'
	}
	// {
	// 	id: 4,
	// 	title: t`SBOM Insights Compliance Envelope`,
	// 	link:
	// 		'https://egvvndocs01.flexera.com/sbom_insights/Content/helplibrary/SBOM_Insights_Administration.htm',
	// 	icon: 'IconFxBeacon',
	// 	hasPermissionCheck: 'hasSbom'
	// }
];
