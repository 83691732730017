import { lazyLoaded } from '@flexera/lib.utilities';

export const Catalog = lazyLoaded(async () => {
	const mod = import('@flexera/automation.catalog');
	return { default: (await mod).AutomationCatalog };
});

export const Templates = lazyLoaded(async () => {
	const mod = import('@flexera/automation.templates');
	return { default: (await mod).AutomationTemplates };
});
