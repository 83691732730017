import React from 'react';
import { Environment, environment } from '@flexera/lib.environment';
import { LiveModal } from './LiveModal';
import { LocalModal } from './LocalModal';

const EnforceSsoModal = () => {
	return (
		<>
			{environment === Environment.Local && <LocalModal />}
			{environment !== Environment.Local && <LiveModal />}
		</>
	);
};

export { EnforceSsoModal };
