import { useState } from 'react';
import { useSelector } from 'react-redux';
import { State, Embed } from './state';
import { initialEmbedState } from './reducer';

/**
 * Use child routes
 */
export function useGooddataEmbed(id: string): Embed {
	return useSelector((state: State) => {
		if (state.gooddataEmbedState.embeds[id]) {
			return state.gooddataEmbedState.embeds[id];
		}
		return initialEmbedState;
	});
}
