/* eslint-disable no-nested-ternary */
import { fetch } from '@flexera/lib.http';
import { factory } from '@gooddata/gooddata-js';
import {
	GOODDATA_API_DOMAIN,
	IAM_URL,
	SPEND_ANALYTICS_API_URL,
	ITV_WORKSPACE_API,
	FSM_SVC_URL
} from '@flexera/lib.api';
import { Environment, environment } from '@flexera/lib.environment';
import { KPI_DASHBOARD_TEMPLATE, PP_DASHBOARD_TEMPLATE } from './Constants';

export interface KPIDashboardProps {
	analyticalDashboard?: {
		meta?: {
			title?: string;
			identifier?: string;
		};
	};
}

interface QueryResponse {
	[key: string]: any;
}

interface WorkspaceResponse {
	data?: object;
}

interface WorkspaceResponseBody {
	workspaceURI?: string;
}

export interface DashboardResponse {
	status: 'success' | 'error';
	data: any;
}

export interface QueryOptions {
	[key: string]: any;
}

const gooddata = factory({ domain: GOODDATA_API_DOMAIN });

/**
 * Should be used to check that
 * the message being sent to Fusion
 * is from a valid known GD Host
 * @param messageHost string
 */
export const isValidGDMessageHost = (messageHost: string) => {
	const validHosts = [
		/embeddedanalytics\.flexera\.com$/,
		/embeddedanalytics\.flexeratest\.com$/,
		/embeddedanalytics\.flexera\.eu$/
	];
	return !!validHosts.find((host) => host.test(messageHost));
};

/**
 * Get Workspace ID
 * for a given org
 *
 * @param capability - the requesting capability
 * @param orgID - current org
 */
export const getWorkspace = async (
	capability: 'vis' | 'sa' | 'saas',
	orgID: string
): Promise<WorkspaceResponse> => {
	const baseDomain =
		capability === 'vis'
			? ITV_WORKSPACE_API
			: capability === 'sa'
			? SPEND_ANALYTICS_API_URL
			: capability === 'saas'
			? FSM_SVC_URL
			: IAM_URL;
	const url = `${baseDomain}${capability}/v1/orgs/${orgID}/workspaces`;
	const { data } = (await fetch(url, {
		authSchemes: ['IAM'],
		headers: {
			'Content-Type': 'application/json'
		}
	})) as WorkspaceResponse;
	return data;
};

/**
 * Get Workspace ID
 * for a given org
 *
 * @param capability - the requesting capability
 * @param orgID - current org
 */
export const getDataProductWorkspace = async (
	capability: 'vis',
	orgID: string
): Promise<WorkspaceResponse> => {
	const baseDomain = capability === 'vis' ? ITV_WORKSPACE_API : IAM_URL;
	const url = `${baseDomain}${capability}/v1/orgs/${orgID}/workspaces`;
	const { data } = (await fetch(url, {
		authSchemes: ['IAM'],
		headers: {
			'Content-Type': 'application/json'
		}
	})) as WorkspaceResponse;
	return data;
};

/**
 * GoodData metadata wrapper
 * @param projectId
 * @param queryOptions
 */
const getObjectsByQuery = async (
	projectId: string,
	queryOptions: QueryOptions
): Promise<any> => gooddata.md.getObjectsByQuery(projectId, queryOptions);

/**
 * Depending on the type of query the accessor is subject to change.
 * @param dashboardIdentifier
 * @param response
 * @param accessor
 */
const filterByDashboardIdentifier = (
	dashboardIdentifier: string,
	response: any[],
	accessor: string
) => {
	return response.find((dashboard: any) => {
		return dashboard[accessor].meta.identifier === dashboardIdentifier;
	});
};

/**
 * GoodData can return a json object or a string error. Handle both.
 * @param apiError
 */
const handleObjectByQueryError = (apiError: any): DashboardResponse => {
	const status = 'error';
	let data: any = null;
	try {
		const errorResponse = JSON.parse(apiError.responseBody);
		const { message } = errorResponse.error;
		console.warn(
			'GoodData getObjectsByQuery/projectDashboard failed because: ',
			message
		);
		data = message;
	} catch (e) {
		// JSON parse error - apiError is string?
		data = apiError.toString();
	}
	return { status, data };
};

/**
 * Retrieve KPI Dashboards
 * @param projectId
 * @param dashboardIdentifier
 */
export const getAllAnalyticalDashboards = async (
	projectId: string
): Promise<any> => {
	if ([Environment.Local].includes(environment)) {
		const mock = [
			{
				analyticalDashboard: {
					meta: {
						identifier: 'abeQDabzbEy0',
						title: '1. ITV Software (PP)'
					}
				}
			},
			{
				analyticalDashboard: {
					meta: {
						identifier: 'aaCu9OBUHltZ',
						title: '2. Demo ITV Dashboard'
					}
				}
			},
			{
				analyticalDashboard: {
					meta: {
						identifier: 'ad1tDmSNqeSj',
						title: '3. SW Details'
					}
				}
			},
			{
				analyticalDashboard: {
					meta: {
						identifier: 'acu3AS2l78Zl',
						title: '4. SW Treemap'
					}
				}
			}
		];
		return {
			status: 'success',
			data: mock
		};
	}
	return getObjectsByQuery(projectId, { category: 'analyticalDashboard' })
		.then((response: any) => {
			const status = response ? 'success' : 'error';
			return { status, data: response };
		})
		.catch(handleObjectByQueryError);
};

/**
 * Maps the dashboards for select
 * @param kpiDashboardResponse
 * @returns
 */
export const mapKpiDashboardsForSelect = (
	kpiDashboardResponse: KPIDashboardProps[]
): { label: string; value: string }[] => {
	const mappedList = kpiDashboardResponse.map((dashboard) => {
		const { title, identifier } = dashboard?.analyticalDashboard?.meta;
		return { label: title, value: identifier };
	});

	return mappedList.sort((a, b) => a.label.localeCompare(b.label));
};

/**
 * Maps the dashboards for list
 * @param kpiDashboardResponse
 * @returns
 */
export const mapKpiDashboardsForList = (
	kpiDashboardResponse: KPIDashboardProps[]
): { title: string; data?: { identifier: string } }[] => {
	const mappedList = kpiDashboardResponse?.map((dashboard) => {
		const { title, identifier } = dashboard?.analyticalDashboard?.meta;
		return { title, data: { identifier } };
	});

	return mappedList.sort((a, b) =>
		a.title.localeCompare(b.title, undefined, {
			numeric: true,
			sensitivity: 'base'
		})
	);
};

/**
 * Retrieve KPI Dashboard ID
 * @param projectId
 * @param dashboardIdentifier
 */
export const getAnalyticalDashboardId = async (
	projectId: string,
	dashboardIdentifier: string
): Promise<any> => {
	if (
		[Environment.Local].includes(environment) &&
		dashboardIdentifier === 'ad4UekZLigEu'
	) {
		return {
			status: 'success',
			data: dashboardIdentifier
		};
	}
	return getObjectsByQuery(projectId, { category: 'analyticalDashboard' })
		.then((response: any) => {
			const dashboard = filterByDashboardIdentifier(
				dashboardIdentifier,
				response,
				'analyticalDashboard'
			);
			const status = dashboard ? 'success' : 'error';
			const data = dashboard
				? dashboard.analyticalDashboard.meta.identifier
				: null;
			return { status, data };
		})
		.catch(handleObjectByQueryError);
};

/**
 * Retrieve Pixel Perfect Dashboard ID
 *
 * @param projectId - as taken from getWorkspace response
 * @param dashboardIdentifier - set in GD, constant name of dashboard.
 */
export const getProjectDashboardId = async (
	projectId: string,
	dashboardIdentifier: string
): Promise<any> => {
	if ([Environment.Local].includes(environment)) {
		return {
			status: 'success',
			// Teapot ORg 6 - 38862 only
			data:
				dashboardIdentifier === 'adCSQC7ufhaq'
					? `/gdc/md/${projectId}/obj/896`
					: dashboardIdentifier === 'ad6OEbMMio04'
					? `/gdc/md/${projectId}/obj/903`
					: `/gdc/md/${projectId}/obj/15080`
		};
	}
	return getObjectsByQuery(projectId, { category: 'projectDashboard' })
		.then((response: any) => {
			const dashboard = filterByDashboardIdentifier(
				dashboardIdentifier,
				response,
				'projectDashboard'
			);
			const status = dashboard ? 'success' : 'error';
			const data = dashboard ? dashboard.projectDashboard.meta.uri : null;
			return { status, data };
		})
		.catch(handleObjectByQueryError);
};

/**
 * Trims the project ID from
 * the workspace URI
 *
 * @param workspaceURI - /gdc/projects/PROJECT_ID
 */
export const getProjectIDFromURI = (workspaceURI: string): string => {
	const workspaceURIRegEx = new RegExp('/gdc/projects/.', 'g');
	if (!workspaceURI.match(workspaceURIRegEx))
		// eslint-disable-next-line no-throw-literal
		throw 'Invalid workspaceURI provided - should be in the format of /gdc/projects/*';
	return workspaceURI.split('/')[3];
};

/**
 * Clear down good data session
 * on Fusion logout
 */
export const goodDataLogout = (): Promise<any> => {
	return new Promise((resolve, reject) => {
		try {
			gooddata.user
				.isLoggedIn()
				.then(() => {
					gooddata.user.logout().finally(() => {
						resolve('Good Data user successfully signed out');
					});
				})
				.catch((error) => {
					reject(error);
				});
		} catch (error) {
			reject(error);
		}
	});
};

/**
 * Combine the above calls
 * to retrieve a full embedded dashboard
 * link. Note: user should be authenticated
 * with Good Data before this.
 *
 * @param capability
 * @param dashboardType
 * @param orgID
 */

export const getEmbeddedDashboardURL = async (
	capability: 'vis' | 'sa',
	dashboardType: 'kpi' | 'pp' | 'customKPI',
	orgID: string,
	dashboardIdentifier: string
): Promise<any> => {
	const { workspaceURI } = (await getWorkspace(
		capability,
		orgID
	)) as WorkspaceResponseBody;
	const projectID = getProjectIDFromURI(workspaceURI);
	const dashboardID = (await getProjectDashboardId(
		projectID,
		dashboardIdentifier
	)) as string;

	const dashboardURL =
		dashboardType === 'kpi' || dashboardType === 'customKPI'
			? KPI_DASHBOARD_TEMPLATE(projectID, dashboardID)
			: PP_DASHBOARD_TEMPLATE(workspaceURI, dashboardID);

	return dashboardURL;
};
