import React from 'react';
import { t } from 'ttag';
import { useDispatch } from 'react-redux';
import {
	Button,
	Modal,
	ModalContent,
	ModalFooter,
	P
} from '@flexera/ui.component-library';
import { setEnforceSsoAction } from '@flexera/lib.auth';
import { useEnforceSsoModal } from '../../hooks';

const LocalModal = () => {
	const dispatch = useDispatch();
	const { isOpen, setIsOpen } = useEnforceSsoModal();
	return (
		<Modal
			width={'sm'}
			isOpen={isOpen}
			id={'global-enforce-sso-modal'}
			onClose={() => dispatch(setEnforceSsoAction(false, ''))}
			closeOnDocumentClick
			title={t`You've switched to an SSO enforced org in a local environment.`}
		>
			<ModalContent>
				<P m={'0'} mb={'0'}>
					{t`Dismiss the modal and switch back to an non enforced SSO org to test the app.`}
				</P>
			</ModalContent>
			<ModalFooter>
				<Button
					type={'button'}
					title={t`Dismiss this message`}
					id={'dismiss-enforce-sso'}
					width={'100%'}
					onClick={() => {
						setIsOpen(false);
					}}
				>
					Dismiss
				</Button>
			</ModalFooter>
		</Modal>
	);
};

export { LocalModal };
