import { ISagaModule } from 'redux-dynamic-modules-saga';
import {gooddataEmbedReducer} from './reducer';
import {State} from './state';
import {gooddataEmbedSaga} from './sagas';

export const goodDataEmbedModule = () =>
	({
		id: 'goodDataEmbed',
		reducerMap: {
			gooddataEmbedState: gooddataEmbedReducer,
		},
		initialActions: [],
		sagas: [gooddataEmbedSaga]
	} as ISagaModule<State>)
