const makeId = (id: string) => `stratus.bootstrap${id}`;

export const BASE = makeId('');
export const LOADING = makeId('.loading');
export const DASHBOARD = makeId('/dashboard');
export const REGISTERBUCKET = makeId('/registerBucket');
export const IMPORTSBOM = makeId('/importSbom');
export const JOBS = makeId('/jobs');
export const Home = makeId('/home');
export const MANAGEBUCKETS = makeId('/manage-buckets');
export const MANAGESBOM = makeId('/manage-sbom-parts');
export const PREFERENCES = makeId('/preferences');
