import { usePageTitle } from '@flexera/shell.navigation';
import {
	Header,
	HeaderBrand,
	HeaderDivider,
	HeaderProduct,
	HeaderRight
} from '@flexera/ui.component-library';
import { BranchIndicator } from '@flexera/header';
import React from 'react';
import { AppSwitcher } from './AppSwitcher';
import { ExpandIcon } from './ExpandIcon';
import { OrgPicker } from './OrgPicker';
import { ProfileMenu } from './ProfileMenu';

export const AppHeader = () => {
	const title = usePageTitle();

	return (
		<Header overflow={'hidden'} id={'header'} role={'heading'} fixed={false}>
			<ExpandIcon />
			<HeaderBrand id={'header-brand'} title={'Flexera logo'} />
			<HeaderDivider />
			<HeaderProduct>{title}</HeaderProduct>
			<HeaderRight>
				<OrgPicker />
				<BranchIndicator />
				<HeaderDivider tall />
				<AppSwitcher />
				<ProfileMenu />
			</HeaderRight>
		</Header>
	);
};
