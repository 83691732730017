/* eslint-disable consistent-return */
import { InvitationPage } from '@flexera/auth.iam';
import { useRoutes } from '@flexera/lib.router';
import { OrgsRouteId } from '@flexera/shell.orgs-routes';
import { LandingPage, OrgsRoot } from '@flexera/shell.org-landing';
import { Splash } from '@flexera/shell.splash';
import { useEffect } from 'react';

export function useAppRoutes() {
	const { addRoutes } = useRoutes();

	useEffect(() => {
		return addRoutes([
			{
				id: 'orgs-root',
				path: '',
				parentId: OrgsRouteId,
				component: OrgsRoot
			},
			{
				id: 'orgs-landing-root',
				path: '/landing',
				parentId: OrgsRouteId,
				component: LandingPage
			},
			{
				id: 'splash-root',
				path: '/splash',
				parentId: OrgsRouteId,
				component: Splash
			},
			{
				id: 'invitations-root',
				path: '/invitations',
				component: InvitationPage
			}
		]);
	}, []);
}
