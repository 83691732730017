/* eslint-disable consistent-return */
import { useAuthenticated } from '@flexera/auth.base';
import { useBaseNav } from '@flexera/shell.base-nav-tree';
import { useHelpHref, useNavItems, Divider } from '@flexera/shell.navigation';
import { useOrgId } from '@flexera/shell.orgs';
import { IconMdFavorited, IconMdHome } from '@flexera/ui.component-library';
import React, { FC, useEffect } from 'react';
import { t } from 'ttag';
import { MenuIds } from './MenuIds';

export const SetupNavMenu: FC = () => {
	const authenticated = useAuthenticated();
	const { addItems } = useNavItems();
	const orgId = useOrgId();
	const supportHref = useHelpHref();

	useBaseNav();

	useEffect(() => {
		if (!authenticated || !orgId) return;

		return addItems([
			{
				label: t`Home`,
				id: MenuIds.Home,
				icon: IconMdHome,
				priority: 10,
				static: true,
				urlMatch: /^\/orgs\/\d+\/landing(\/|$|\?)/,
				path: `/orgs/${orgId}/landing`
			},
			{
				label: t`Favorites`,
				id: MenuIds.Favorites,
				icon: IconMdFavorited,
				priority: 20,
				static: true
			},
			{
				id: 'flex-divider',
				path: 'divider',
				priority: 1000,
				component: () => <div style={{ flex: 1 }} />
			},
			{ id: 'divider-2', component: Divider, path: 'divider', priority: 200 }
		]);
	}, [authenticated, orgId, supportHref]);

	return null;
};
