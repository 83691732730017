import React, { useState, useEffect, useContext } from 'react';
import {
	P,
	Modal,
	Button,
	ModalFooter,
	ModalContent
} from '@flexera/ui.component-library';
import { AuthBaseContext } from '@flexera/auth.base';
import { t } from 'ttag';
import { BroadcastChannel } from 'broadcast-channel';
import { useUserSession } from '../hooks';
import { determineReturnURL } from '../utilities/determineReturnURL';

export const sessionModalBroadcast: BroadcastChannel = new BroadcastChannel(
	'f1_session_ended'
);

export const SessionTimeoutModal = (): JSX.Element => {
	const { logout } = useContext(AuthBaseContext);
	const sessionState = useUserSession();
	const [isOpen, setIsOpen] = useState(
		sessionState && sessionState.sessionHasEnded
	);

	const gotoLogin = () => {
		logout(determineReturnURL());
	};

	sessionModalBroadcast.onmessage = (messageEvent: { type: string }) => {
		switch (messageEvent.type) {
			case 'session_ended':
				gotoLogin();
				break;
			default:
		}
	};

	useEffect(() => {
		if (sessionState) {
			setIsOpen(sessionState.sessionHasEnded);
		}
	}, [sessionState]);

	document.onkeyup = (e) => {
		if (e.key === 'Escape' && isOpen) {
			setIsOpen(false);
		}
	};

	return (
		<Modal
			id={'timeout-expired'}
			isOpen={isOpen}
			width={'432px'}
			title={t`Your session has ended`}
			setIsOpen={setIsOpen}
			onClose={() => {
				gotoLogin();
				sessionModalBroadcast.postMessage({ type: 'session_ended' });
			}}
		>
			<ModalContent>
				<P mb={'0px'}>{t`Your session was ended due to inactivity.`}</P>
			</ModalContent>
			<ModalFooter>
				<Button
					id={'logout-button'}
					type={'button'}
					title={t`Login`}
					mr={'sm'}
					variant={'primary'}
					onClick={() => {
						setIsOpen(false);
					}}
				>
					{t`Login`}
				</Button>
			</ModalFooter>
		</Modal>
	);
};
