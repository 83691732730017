import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import {
	filterNavItems,
	NavItem,
	useDebounce
} from '@flexera/shell.navigation';
import { storeItem } from '@flexera/lib.setting-storage';
import { t } from 'ttag';
import { Icon, IconMdSearch, IconMdClear } from '@flexera/ui.component-library';
import { useOrgId } from '@flexera/shell.orgs';
import { Menu } from '../Menu/Menu';
import { NavigationLogo } from '../Navigation.styled';
import { NavigationContext } from '../navigationContext';
import { SearchForm, SearchLabel, NoResultsMessage } from './Search.styled';
import { SearchContext } from './SearchContext';
import { RecentSearches } from './RecentSearches/RecentSearches';
import { ExpandedLogo, CollapsedLogo } from '../../assets';
import {
	getRecentSearches,
	filterRecentSearch,
	toggleEnterKey
} from '../../utilities';
import { navSearchId, noSearchResults } from '../constants';

interface SearchProps {
	items: NavItem[];
}

export const Search = ({ items }: SearchProps) => {
	const [searchWord, setSearchWord] = React.useState('');
	const [matchedItems, setMatchedItems] = React.useState([]);
	const [recentSearches, setRecentSearches] = React.useState([]);
	const {
		isExpanded,
		setIsExpanded,
		searchIsActive,
		setSearchIsActive,
		isPinned,
		setIsPinned,
		setPinnedItem
	} = React.useContext(NavigationContext);
	const orgId = useOrgId();
	const inputRef = useRef(null);

	const getMatches = useDebounce(() => {
		if (items && items.length > 0) {
			const results = filterNavItems(searchWord, items);
			if (results.length > 0) {
				const filteredRecentSearches = filterRecentSearch(
					searchWord,
					recentSearches
				);
				setRecentSearches(filteredRecentSearches);
				storeItem({
					key: `recent-searches`,
					data: filteredRecentSearches,
					orgId
				});
			}
			setMatchedItems(results);
		}
	}, 300);

	const handleActivateSearch = () => {
		if (!isExpanded) setIsExpanded(true);
		if (isPinned) {
			setPinnedItem('');
			setIsPinned(!isPinned);
		}
		if (!searchIsActive) setSearchIsActive(true);
	};

	const handleResetSearch = () => {
		inputRef?.current?.focus();
		setSearchWord('');
	};

	const handleCancelSearch = () => {
		setSearchIsActive(false);
		setSearchWord('');
	};

	const matchedMenu = <Menu items={matchedItems} searchWord={searchWord} />;

	useEffect(() => {
		const fetchSearches = async () => {
			const fetchedSearches = await getRecentSearches(orgId);
			setRecentSearches(fetchedSearches);
		};

		fetchSearches();
	}, [orgId]);

	useEffect(() => {
		if (searchWord !== '') {
			getMatches();
		}
	}, [searchWord]);

	return (
		<SearchContext.Provider value={{ searchWord }}>
			<NavigationLogo id={'nav-logo'} isExpanded={isExpanded}>
				<Link to={'/'}>
					<span className={'expanded-logo'}>
						<Icon src={ExpandedLogo} width={'auto'} height={'auto'} />
					</span>
					<img
						className={'collapsed-logo'}
						alt={'Collapsed Logo'}
						src={CollapsedLogo}
						width={'30px'}
						height={'30px'}
					/>
				</Link>
				<SearchForm
					id={navSearchId}
					tabIndex={-1}
					isExpanded={isExpanded}
					role={'search'}
					onSubmit={(e) => e.preventDefault()}
					searchIsActive={searchIsActive}
				>
					<SearchLabel
						tabIndex={-1}
						htmlFor={'search-input'}
						isExpanded={isExpanded}
						searchIsActive={searchIsActive}
					>
						<input
							tabIndex={searchIsActive ? 0 : -1}
							ref={inputRef}
							type={'text'}
							id={'search-input'}
							placeholder={'Search'}
							autoComplete={'off'}
							aria-label={'Navigation Search'}
							value={searchIsActive ? searchWord : ''}
							onKeyDown={(e) => toggleEnterKey(e, handleActivateSearch)}
							onChange={(e) => setSearchWord(e.target.value)}
							onClick={() => handleActivateSearch()}
						/>
						<button
							type={'button'}
							tabIndex={0}
							className={'searchButton'}
							aria-label={searchIsActive ? 'Clear your search' : 'Begin Searching'}
							onClick={() =>
								searchIsActive
									? handleResetSearch()
									: setSearchIsActive(!searchIsActive)
							}
							onKeyDown={(e) => toggleEnterKey(e, handleActivateSearch)}
						>
							<Icon src={searchIsActive ? IconMdClear : IconMdSearch} />
						</button>
					</SearchLabel>
					<button
						name={'cancel-search'}
						type={'button'}
						aria-label={'Cancel Search'}
						disabled={!searchIsActive}
						className={'cancelSearchButton'}
						onClick={() => handleCancelSearch()}
						onKeyDown={(e) => toggleEnterKey(e, handleCancelSearch)}
					>
						{t`Cancel`}
					</button>
				</SearchForm>
			</NavigationLogo>

			{searchIsActive && searchWord === '' && (
				<RecentSearches
					recentSearches={recentSearches}
					setSearchWord={setSearchWord}
				/>
			)}

			{searchWord !== '' && matchedItems.length === 0 && (
				<NoResultsMessage>{noSearchResults}</NoResultsMessage>
			)}

			{searchIsActive &&
				matchedItems.length > 0 &&
				searchWord !== '' &&
				matchedMenu}
		</SearchContext.Provider>
	);
};
