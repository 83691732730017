import { AuthBaseContext } from '@flexera/auth.base';
import { createOktaWidget } from '@flexera/lib.auth';
import { Loader } from '@flexera/lib.loader';
import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useFetchProfile } from '../hooks';
import { useAcceptInvitation } from '../hooks/useAcceptInvitation';
import { State } from '../State';
import { useAuthRoutes } from './useAuthRoutes';

export const AuthProvider: FC = ({ children }) => {
	const history = useHistory();
	const userId = useSelector((state: State) => state.iamAuth.userId);
	const userProfile = useFetchProfile(userId);
	const [refreshTimestamp, setRefreshTimestamp] = useState<string>(null);
	const inviteLoading = useAcceptInvitation(userId, setRefreshTimestamp);
	const token = window.localStorage.getItem('access_token');

	useEffect(() => {
		if (userId) {
			createOktaWidget('');
		}
	}, [userId]);

	useAuthRoutes();

	if (inviteLoading || (!userProfile && token !== null && token !== 'null')) {
		return <Loader />;
	}

	return (
		<AuthBaseContext.Provider
			value={{
				userId,
				userProfile,
				refreshTimestamp,
				setRefreshTimestamp,
				goToLogin: (returnUrl?: string) => {
					if (returnUrl) {
						window.sessionStorage.setItem('returnUrl', returnUrl);
					}
					history.push('/login');
				},
				logout: (returnUrl?: string) => {
					if (returnUrl) {
						window.sessionStorage.setItem('returnUrl', returnUrl);
					}
					window.location.href = '/logout';
				}
			}}
		>
			{children}
		</AuthBaseContext.Provider>
	);
};
