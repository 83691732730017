import { call, put, takeLatest } from '@redux-saga/core/effects';
import { AnyAction } from 'redux';
import { t } from 'ttag';
import { Context, Status } from './state';
import {
	getAnalyticalDashboardId,
	getProjectDashboardId,
	getDataProductWorkspace,
	getWorkspace,
	getAllAnalyticalDashboards
} from './service/GoodData';
import {
	setDashboardId,
	setEmbedStatusAction,
	setWorkspaceURIAction,
	setKPIDashboards
} from './actions';
import {
	GET_WORKSPACE_URI_ACTION,
	GET_DASHBOARD_URI_ACTION,
	GET_KPI_DASHBOARDS_ACTION
} from './constants';

interface DataProducts {
	workspaceId: string;
	workspaceUri: string;
}

const dataProductEnabledCapabilities = ['vis'];

export function* getWorkspaceURISaga({
	dataProduct,
	capability,
	orgId,
	id
}: AnyAction): Generator {
	try {
		if (dataProductEnabledCapabilities.includes(capability)) {
			const data = yield call(() => getDataProductWorkspace(capability, orgId));
			const matchingDataProduct = (data as DataProducts[]).find(
				(workspaces) => workspaces.workspaceId === dataProduct
			);
			if (matchingDataProduct) {
				yield put(setWorkspaceURIAction(id, matchingDataProduct.workspaceUri));
			} else {
				yield put(
					setEmbedStatusAction(
						id,
						Status.Error,
						t`Could not retrieve Dashboard. This organization does not have a matching data product.`,
						Context.Workspace
					)
				);
			}
		} else {
			const { workspaceURI } = yield call(() => getWorkspace(capability, orgId));
			yield put(setWorkspaceURIAction(id, workspaceURI));
		}
	} catch (error) {
		yield put(
			setEmbedStatusAction(
				id,
				Status.Error,
				t`Could not retrieve Dashboard. This organization may not be assigned access to this workspace.`,
				Context.Workspace
			)
		);
	}
}

export function* getDashboardIDSaga({
	id,
	projectId,
	dashboardIdentifier,
	dashboardType
}: AnyAction): Generator {
	const errorMessage = t`Could not retrieve Dashboard. This organization may not be assigned access to this dashboard.`;
	try {
		const { status, data } = yield call(() =>
			dashboardType === 'PixelPerfect'
				? getProjectDashboardId(projectId, dashboardIdentifier)
				: getAnalyticalDashboardId(projectId, dashboardIdentifier)
		);
		if (status === 'success') {
			yield put(setDashboardId(id, data));
		} else {
			throw errorMessage;
		}
	} catch (error) {
		yield put(
			setEmbedStatusAction(id, Status.Error, errorMessage, Context.Dashboard)
		);
	}
}

export function* getKPIDashboardSaga({ id, projectId }: AnyAction): Generator {
	const errorMessage = t`Could not retrieve Dashboard list.`;
	try {
		const { status, data } = yield call(() =>
			getAllAnalyticalDashboards(projectId)
		);
		if (status === 'success') {
			yield put(setKPIDashboards(id, data));
		} else {
			throw errorMessage;
		}
	} catch (error) {
		yield put(
			setEmbedStatusAction(id, Status.Error, errorMessage, Context.Dashboard)
		);
	}
}

export function* gooddataEmbedSaga() {
	yield takeLatest(GET_WORKSPACE_URI_ACTION, getWorkspaceURISaga);
	yield takeLatest(GET_DASHBOARD_URI_ACTION, getDashboardIDSaga);
	yield takeLatest(GET_KPI_DASHBOARDS_ACTION, getKPIDashboardSaga);
}
