import { withCapability } from '@flexera/lib.permissions';
import { lazyLoaded } from '@flexera/lib.utilities';

export const GovernanceApp = withCapability(
	lazyLoaded(async () => {
		const mod = import('@flexera/governance.legacy-integration');
		return { default: (await mod).LegacyGovernance };
	}),
	'policy'
);

export const UserManagement = withCapability(
	lazyLoaded(async () => {
		const mod = import('@flexera/admin.user-management');
		return { default: (await mod).UsersWrapper };
	}),
	'iam'
);

export const Invitations = withCapability(
	lazyLoaded(async () => {
		const mod = import('@flexera/admin.invitations');
		return { default: (await mod).InvitationsPage };
	}),
	'iam'
);

export const IdentityProviders = withCapability(
	lazyLoaded(async () => {
		const mod = import('@flexera/admin.identity-providers');
		return { default: (await mod).IdentityProvidersWrapper };
	}),
	'iam'
);

export const NewIdentityProvider = withCapability(
	lazyLoaded(async () => {
		const mod = import('@flexera/admin.identity-providers');
		return { default: (await mod).NewIdentityProvider };
	}),
	'iam'
);
