import { useRoutes as useRoutesHook } from '@flexera/lib.router';
import { OrgsRouteId } from '@flexera/shell.orgs-routes';
import { useEffect } from 'react';
import {
	GovernanceApp,
	IdentityProviders,
	Invitations,
	NewIdentityProvider,
	UserManagement
} from './AdminApp';

export function useRoutes() {
	const { addRoutes } = useRoutesHook();

	useEffect(() => {
		return addRoutes([
			{
				id: 'admin.bootstrap/admin/users',
				parentId: OrgsRouteId,
				path: '/admin/users',
				exact: false,
				component: GovernanceApp
			},
			{
				id: 'admin.bootstrap/admin/groups',
				parentId: OrgsRouteId,
				path: '/admin/groups',
				exact: false,
				component: GovernanceApp
			},
			{
				id: 'admin.bootstrap/admin/accounts',
				parentId: OrgsRouteId,
				path: '/admin/accounts',
				exact: false,
				component: GovernanceApp
			},
			{
				id: 'admin.bootstrap/admin/invitations',
				parentId: OrgsRouteId,
				path: '/admin/invitations',
				exact: false,
				component: Invitations
			},
			{
				id: 'admin.bootstrap/admin/identity-providers',
				parentId: OrgsRouteId,
				path: '/admin/identity-providers',
				exact: true,
				component: IdentityProviders
			},
			{
				id: 'admin.bootstrap/admin/user-management',
				parentId: OrgsRouteId,
				path: '/admin/user-management',
				exact: true,
				component: UserManagement
			},
			{
				id: 'admin.bootstrap/admin/identity-providers/new',
				parentId: OrgsRouteId,
				path: '/admin/identity-providers/new',
				exact: true,
				component: NewIdentityProvider
			}
		]);
	}, []);
}
