import {
	Permissions,
	OrPermission,
	PrivilegePermission
} from '@flexera/lib.permissions';

const { commonOrgOwner } = Permissions;

export const userCreate = new OrPermission(
	commonOrgOwner,
	new PrivilegePermission('platform:user:create'),
	new PrivilegePermission('iam:affiliation:create')
);
export const userDelete = new OrPermission(
	commonOrgOwner,
	new PrivilegePermission('platform:user:delete'),
	new PrivilegePermission('iam:affiliation:delete')
);
export const userIndex = new OrPermission(
	commonOrgOwner,
	new PrivilegePermission('platform:user:index'),
	new PrivilegePermission('iam:user:index')
);
export const userReplace = new OrPermission(
	commonOrgOwner,
	new PrivilegePermission('platform:user:replace'),
	new PrivilegePermission('iam:affiliation:replace')
);
export const userShow = new OrPermission(
	commonOrgOwner,
	new PrivilegePermission('platform:user:show'),
	new PrivilegePermission('iam:user:show')
);

export const orgUserIndex = new OrPermission(
	commonOrgOwner,
	new PrivilegePermission('platform:org_user:index')
);
export const orgUserShow = new OrPermission(
	commonOrgOwner,
	new PrivilegePermission('platform:org_user:show')
);

export const groupCreate = new OrPermission(
	commonOrgOwner,
	new PrivilegePermission('platform:group:create'),
	new PrivilegePermission('iam:group:create')
);
export const groupDelete = new OrPermission(
	commonOrgOwner,
	new PrivilegePermission('platform:group:delete'),
	new PrivilegePermission('iam:group:delete')
);
export const groupIndex = new OrPermission(
	commonOrgOwner,
	new PrivilegePermission('platform:group:index'),
	new PrivilegePermission('iam:group:index')
);
export const groupReplace = new OrPermission(
	commonOrgOwner,
	new PrivilegePermission('platform:group:replace')
);
export const groupShow = new OrPermission(
	commonOrgOwner,
	new PrivilegePermission('platform:group:show'),
	new PrivilegePermission('iam:group:show')
);

export const projectShow = new OrPermission(
	commonOrgOwner,
	new PrivilegePermission('platform:project:show'),
	new PrivilegePermission('iam:project:show')
);

export const accessRuleIndex = new OrPermission(
	commonOrgOwner,
	new PrivilegePermission('platform:access_rule:index'),
	new PrivilegePermission('iam:access_rule:index')
);
export const accessRuleGrant = new OrPermission(
	commonOrgOwner,
	new PrivilegePermission('platform:access_rule:grant'),
	new PrivilegePermission('iam:access_rule:grant')
);
export const accessRuleRevoke = new OrPermission(
	commonOrgOwner,
	new PrivilegePermission('platform:access_rule_revoke'),
	new PrivilegePermission('iam:access_rule:revoke')
);

export const appliedPolicyIndex = new OrPermission(
	commonOrgOwner,
	new PrivilegePermission('governance:applied_policy:index')
);
export const appliedPolicyShow = new OrPermission(
	commonOrgOwner,
	new PrivilegePermission('governance:applied_policy:show')
);
export const incidentShow = new OrPermission(
	commonOrgOwner,
	new PrivilegePermission('governance:incident:show')
);
export const policyTemplateShow = new OrPermission(
	commonOrgOwner,
	new PrivilegePermission('governance:policy_template:show')
);
export const appliedPolicyCreate = new OrPermission(
	commonOrgOwner,
	new PrivilegePermission('governance:applied_policy:create')
);
export const publishedTemplateCreate = new OrPermission(
	commonOrgOwner,
	new PrivilegePermission('governance:published_template:index')
);
export const publishedTemplateIndex = new OrPermission(
	commonOrgOwner,
	new PrivilegePermission('governance:published_template:index')
);
export const cloudCredentialsIndex = new OrPermission(
	commonOrgOwner,
	new PrivilegePermission('cloud:credentials:index')
);
