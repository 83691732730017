import React from 'react';
import { DynamicModuleLoader } from 'redux-dynamic-modules';
import { IAMmodule } from '../module';
import { InactivityTimeoutModal } from './InactivityTimeoutModal';
import { SessionTimeoutModal } from './SessionTimeoutModal';

const InactivityTimeout = () => (
	<DynamicModuleLoader modules={[IAMmodule()]}>
		<InactivityTimeoutModal />
		<SessionTimeoutModal />
	</DynamicModuleLoader>
);

export { InactivityTimeout };
