import { ReactNode, ComponentType } from 'react';

export interface MenuItem {
	/**
	 * Unique id that should be global to the whole system.
	 * It is good practice to use your package name as prefix to the id in order
	 * to avoid collisions with items from other packages.
	 */
	id: string;

	/**
	 * The label is typically the main text rendered by components.
	 */
	label?: string;

	/**
	 * Id of the parent item in the navigation hierarchy.
	 */
	parentId?: string;

	/**
	 * Longer description for the navigation item, some nav components may render
	 * this to provide users with more context.
	 */
	description?: string;

	// TODO: remove this and use label
	category?: string;

	/**
	 * This is the destination path of the navigation item.
	 */
	path?: string;

	/**
	 * Navigation components might support rendering an icon next to the label.
	 * It should be provided here.
	 */
	icon?: ReactNode;

	/**
	 * Sometimes we want to render a custom component instead of the standard
	 * label, use this property in those cases.
	 */
	component?: ComponentType<any>;

	/**
	 * This is populated by the system and provides an array of child items.
	 */
	children?: MenuItem[];

	/**
	 * A regular expression which allows the system to determine whether the
	 * current url matches the nav item.
	 */
	urlMatch?: RegExp;

	/**
	 * Use this to set a page title for this item.
	 * The usePageTitle hook can be used to get the title from the current item
	 * or its closest parent that has a title.
	 */
	pageTitle?: string;

	/**
	 * Use this to set a URL for the help page that corresponds to this nav item.
	 * The useHelpHref hook can be used to get the help href for the current item
	 * or its closest parent that has a help href.
	 */
	helpHref?: string;

	/**
	 * MenuItems are sorted by priority from lowest to highest number.
	 * Default is 100.
	 */
	priority?: number;

	// TODO: fix this when addressingt he custom paths
	static?: boolean;

	// TODO: see if that's needed for anything
	external?: boolean;
	onClick?: () => boolean;

	/**
	 * Visibility of nav item
	 */
	hidden?: boolean;
}

export type MenuItemDef = Omit<MenuItem, 'children'>;
export type MenuItemOverride = Omit<MenuItemDef, 'id'>;
