import React, { useState, useEffect, useRef } from 'react';
import { t } from 'ttag';
import { Icon } from '@flexera/ui.component-library';
import { getSavedFavorites, FavoriteItem } from '@flexera/shell.favorites';
import { useOrgId } from '@flexera/shell.orgs';
import { NavItem, NavItemChild } from '@flexera/shell.navigation';
import { Menu } from './Menu/Menu';
import { Search } from './Search/Search';
import { NavigationContext } from './navigationContext';
import { NavigationWrapper, NavFooter } from './Navigation.styled';
import { NavExpandCollapse } from '../assets';
import { defaultFavoritesMenu, navId, navFooterId } from './constants';
import { getCleanItems, getFavoritesMenu } from '../utilities';

export const Navigation = ({ items }: NavItem[]) => {
	const isStratusAppLogin = true;
	const initialPinnedItem = 'SBOMInsights';
	const [isExpanded, setIsExpanded] = useState(!isStratusAppLogin);
	const [searchIsActive, setSearchIsActive] = useState(false);
	const [pinnedItem, setPinnedItem] = useState(initialPinnedItem);
	const [isPinned, setIsPinned] = useState(isStratusAppLogin);
	const [favoritesMenu, setFavoritesMenu] = useState<NavItemChild[]>(
		defaultFavoritesMenu
	);
	const [savedFavorites, setSavedFavorites] = useState<FavoriteItem[]>([]);
	const [resizerActive, setResizerActive] = useState(false);
	const [currentItems, setCurrentItems] = useState(null);
	const [hasFlyout, setHasFlyout] = useState(false);
	const navWrapperRef = useRef();

	const orgId = useOrgId();
	const handleOutsideClick = (e) => {
		if (!navWrapperRef.current.contains(e.target)) {
			setSearchIsActive(false);
		}
	};

	/**
	 * This is listening to items, because we need to
	 * generate the favorites menu based on the most up-to-date
	 * items, not on initial load, as items on load includes placeholder
	 * 'loading' items. When the items resolves, we'll get the menu we expect.
	 * */
	useEffect(() => {
		getFavoritesMenu(items, savedFavorites, setFavoritesMenu);
		setCurrentItems(getCleanItems(items));
	}, [items]);

	useEffect(() => {
		if (savedFavorites) {
			getFavoritesMenu(items, savedFavorites, setFavoritesMenu);
		}
	}, [savedFavorites]);

	/**
	 *  Update our saved favorite items every time orgId changes
	 */
	useEffect(() => {
		const getFavorites = async () => {
			const favesList = await getSavedFavorites(orgId);
			if (favesList) {
				setSavedFavorites(favesList);
			}
		};
		if (orgId) {
			getFavorites();
		}
	}, [orgId]);

	useEffect(() => {
		if (searchIsActive) {
			document.addEventListener('mouseup', handleOutsideClick);
		} else {
			document.removeEventListener('mouseup', handleOutsideClick);
		}
		return () => {
			document.removeEventListener('mouseup', handleOutsideClick);
		};
	}, [searchIsActive]);

	const handleExpandCollapse = () => {
		if (isPinned) {
			setPinnedItem('');
			setIsPinned(!isPinned);
		}
		setIsExpanded(!isExpanded);
	};

	const determineClassName = () => {
		if (isPinned) return 'pinned';
		if (!isExpanded) return 'collapsed';
		return '';
	};

	const mainNavMenu = <Menu items={currentItems} />;

	return (
		<NavigationContext.Provider
			value={{
				isExpanded,
				setIsExpanded,
				searchIsActive,
				setSearchIsActive,
				isPinned,
				setIsPinned,
				setPinnedItem,
				favoritesMenu,
				resizerActive,
				setResizerActive,
				navWrapperRef,
				orgId,
				savedFavorites,
				setSavedFavorites,
				hasFlyout,
				setHasFlyout
			}}
		>
			<NavigationWrapper
				id={navId}
				className={determineClassName()}
				selector={pinnedItem}
				ref={navWrapperRef}
			>
				<Search items={items} />
				{!searchIsActive && mainNavMenu}
				<NavFooter isExpanded={isExpanded} id={navFooterId}>
					<button
						type={'button'}
						title={
							searchIsActive
								? t`Expand is disabled when Search is active.`
								: t`Collapse or expand the menu`
						}
						onClick={() => handleExpandCollapse()}
						disabled={searchIsActive}
					>
						<Icon src={NavExpandCollapse} />
					</button>
				</NavFooter>
			</NavigationWrapper>
		</NavigationContext.Provider>
	);
};
