import { useContext } from 'react';
import { NavContext } from './context/NavContext';

/**
 * Builds a menu from the navigation items, either top level or from the children of a particular item.
 *
 * Items without a path are automatically assigned the path of a child. If the item still doesn't
 * have a path, it will be excluded from the menu. This makes it possible to have a container item
 * visible only when appropriate and without complex permission logic.
 *
 * Children are returned up to a given depth, allowing the creation of multi-level menus as needed.
 *
 * @param depth how many level of children should be returned
 * @param parentId Optional id of the parent item
 */
export function useMenu(parentId: string = null) {
	const { subMenu } = useContext(NavContext);

	return subMenu(parentId);
}
