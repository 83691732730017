import React, { useState, useEffect, useContext } from 'react';
import { useDispatch } from 'react-redux';
import {
	P,
	Modal,
	Button,
	ModalFooter,
	ModalContent
} from '@flexera/ui.component-library';
import {
	callTokenRefresh,
	setSessionWillTimeoutAction
} from '@flexera/lib.auth';
import { AuthBaseContext } from '@flexera/auth.base';
import { BroadcastChannel } from 'broadcast-channel';
import { t } from 'ttag';
import { useUserSession } from '../hooks';
import { determineReturnURL } from '../utilities/determineReturnURL';

/**
 * The first of two modals that appears when the user is about to be logged out.
 * This modal gives the user the option of continuing the session or logging out
 * entirely.
 */

export const warningModalBroadcast: BroadcastChannel = new BroadcastChannel(
	'f1_session_warning'
);

export const InactivityTimeoutModal = (): JSX.Element => {
	const { logout } = useContext(AuthBaseContext);
	const dispatch = useDispatch();
	const sessionState = useUserSession();
	const [isOpen, setIsOpen] = useState(false);

	useEffect(() => {
		if (sessionState) {
			if (sessionState.sessionWillTimeOut === 'open') {
				setIsOpen(true);
			} else if (sessionState.sessionWillTimeOut === 'close') {
				if (!sessionState.sessionHasEnded) {
					dispatch(callTokenRefresh());
				}
				setIsOpen(false);
			}
		}
	}, [sessionState]);

	const closeModal = () => {
		dispatch(setSessionWillTimeoutAction('close'));
		setIsOpen(false);
	};

	const closeAndLogout = () => {
		setIsOpen(false);
		logout(determineReturnURL());
	};

	warningModalBroadcast.onmessage = (messageEvent: { type: string }) => {
		switch (messageEvent.type) {
			case 'session_logout':
				closeAndLogout();
				break;
			case 'session_keep_alive':
				setIsOpen(false);
				break;
			default:
		}
	};

	document.onkeyup = (e) => {
		if (e.key === 'Escape' && isOpen) {
			setIsOpen(false);
		}
	};

	return (
		<Modal
			id={'timeout-warning'}
			isOpen={isOpen}
			title={t`Do you need more time?`}
			width={'432px'}
			setIsOpen={setIsOpen}
			onClose={() => {
				closeModal();
			}}
		>
			<ModalContent>
				<P mb={'0px'}>
					{t`For your protection, your session is about to expire due to inactivity.`}
				</P>
			</ModalContent>
			<ModalFooter>
				<Button
					id={'logout-button'}
					type={'button'}
					title={t`Logout`}
					mr={'sm'}
					variant={'outline'}
					onClick={() => {
						closeAndLogout();
						warningModalBroadcast.postMessage({ type: 'session_logout' });
					}}
				>
					{t`Logout`}
				</Button>
				<Button
					id={'still-here-button'}
					type={'button'}
					title={t`Still Here`}
					mr={'sm'}
					variant={'primary'}
					onClick={() => {
						setIsOpen(false);
						warningModalBroadcast.postMessage({ type: 'session_keep_alive' });
					}}
				>
					{t`I'm Still Here`}
				</Button>
			</ModalFooter>
		</Modal>
	);
};
