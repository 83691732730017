/* eslint-disable no-console */
import { oktaSignOut, destroyToken } from '@flexera/lib.auth';
import { goodDataLogout } from '@flexera/lib.gooddata';
import { Loader } from '@flexera/lib.loader';
import { promiseTimeout } from '@flexera/lib.utilities';
import React, { useEffect } from 'react';

async function processLogout() {
	const ssoLogoutRedirectURL = localStorage.getItem('ssoLogoutRedirectUrl');
	try {
		// f1 access token
		try {
			const destroyTokenResult = await destroyToken();
			console.log(`Token destroy successful - ${destroyTokenResult}`);
		} catch (error) {
			console.warn(`Token destroy failed with: ${error}`);
		}
		// okta
		try {
			const oktaSignOutResult = await oktaSignOut();
			console.log(`Okta sign out successful - ${oktaSignOutResult}`);
		} catch (error) {
			console.warn(`Okta sign out failed with: ${error}`);
		}
		localStorage.removeItem('access_token');
		localStorage.removeItem('auth.starttime');
		localStorage.removeItem('auth.refresh');
		localStorage.removeItem('inactivityDetected');
		localStorage.removeItem('warningTriggered');
		localStorage.removeItem('sessionWillTimeout');
		localStorage.removeItem('sessionTimeout');
		localStorage.removeItem('userIsActive');
		localStorage.removeItem('sessionWillTimeoutModalDismissed');
		localStorage.removeItem('sessionTimeoutLogout');
		sessionStorage.removeItem('implicitCookieDecline');
		sessionStorage.removeItem('preferences');
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		(window as any).dataLayer = (window as any).dataLayer || [];
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		(window as any).dataLayer.push({ event: 'logout' });
		if (ssoLogoutRedirectURL) {
			window.location.href = ssoLogoutRedirectURL;
			return;
		}
		window.location.pathname = '/login';
	} catch (error) {
		return error;
	}
	return null;
}

/**
 * Renders a loader while performing logout operations
 */
export const Logout = () => {
	useEffect(() => {
		processLogout();
	}, []);

	return <Loader />;
};
