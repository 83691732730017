import styled from 'styled-components';
import { BaseTheme } from '@flexera/ui.component-library';

export const TableWrapper = styled.div`
	background: ${BaseTheme.colors.white};
	table,
	th,
	td {
		border: ${BaseTheme.borders.sm} ${BaseTheme.colors.black};
		text-align: left;
		padding: ${BaseTheme.space.xs};
	}
`;
