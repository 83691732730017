import {
	addRequestInterceptor,
	addResponseInterceptor
} from '@flexera/lib.http';
import { setEnforceSsoAction } from '@flexera/lib.auth';
import { store } from './module';

addRequestInterceptor(async (args) => {
	if (args.init?.authSchemes?.includes('IAM')) {
		// eslint-disable-next-line no-param-reassign
		args.init = {
			...args.init,
			headers: {
				...args.init?.headers,
				Authorization: `Bearer ${localStorage.getItem('access_token')}`
			}
		};
	}
	return args;
});

addResponseInterceptor(async (args) => {
	if (args.init?.authSchemes?.includes('IAM') && args.response.status === 401) {
		setTimeout(() => {
			localStorage.removeItem('access_token');
			window.sessionStorage.setItem(
				'returnUrl',
				`${window.location.pathname}${window.location.search}${window.location.hash}`
			);
			window.location.pathname = '/login';
		});
	}

	return args.response;
});

/**
 * Interceptor that will tell the UI to throw the Enforced SSO modal if calls fail due to
 * the user coming into an enforced sso org without using their IDP
 */
addResponseInterceptor(async (args) => {
	if (args.response.status === 403 && args.response.data.externalId) {
		store.dispatch(setEnforceSsoAction(true, args.response.data.externalId));
	}

	return args.response;
});
