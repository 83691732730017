import { getUserProfile, Profile } from '@flexera/services.grs';
import { useEffect, useState } from 'react';

/**
 * Internal hook to load the profile of the current user
 */

interface ProfileKeys {
	[key: string]: string;
}

/**
 * Maps underscore to camelCase naming
 * for GRS responses
 * @param keysMap
 * @param obj
 */
const renameProfileKeys = (keysMap: ProfileKeys, obj: Profile) =>
	Object.keys(obj).reduce(
		(acc, key) => ({
			...acc,
			...{ [keysMap[key] || key]: obj[key] }
		}),
		{}
	);

export function useFetchProfile(userId: number) {
	const [profile, setProfile] = useState<Profile>(null);
	useEffect(() => {
		async function fetchProfile() {
			const response = await getUserProfile(userId);

			if (response.status === 200) {
				const keysMap = {
					first_name: 'firstName',
					last_name: 'lastName'
				};
				const userProfile = renameProfileKeys(keysMap, response.data) as Profile;
				setProfile(userProfile);
			} else {
				setProfile(null);
			}
		}

		if (userId) {
			fetchProfile();
		} else {
			setProfile(null);
		}
	}, [userId]);

	return profile;
}
