/* eslint-disable no-await-in-loop */
import { respondToUserInvites } from '@flexera/lib.api';
import { getOrgs, Org } from '@flexera/services.grs';
import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';

export function useAcceptInvitation(
	userId: number,
	setRefreshTimestamp: (timepStapme: string) => void
) {
	const history = useHistory();
	const { pathname } = useLocation();
	const [loading, setLoading] = useState(false);

	const navigateToRoot = () => {
		// not an ideal place for the return url, we need to revisit the
		// flow of things and clean this up
		const redirectUrl = window.sessionStorage.getItem('returnUrl');
		if (redirectUrl) {
			window.sessionStorage.removeItem('returnUrl');
			history.push(redirectUrl);
		} else if (pathname === '/login') {
			history.push('/');
		}
	};

	useEffect(() => {
		if (!userId) {
			return;
		}

		setLoading(true);

		async function acceptInvitation(pendingInvitationId: string) {
			const response = await respondToUserInvites(pendingInvitationId, 'accept');
			if (response.status === 204) {
				localStorage.removeItem('pendingInvitationId');

				let orgs: Org[] = [];
				let count = 0;

				const waitForCertainTime = (counter: number) =>
					new Promise((resolve) => setTimeout(resolve, counter * 200));

				// Loop over a few times to try and get the orgs list before moving on
				while (!orgs?.length && count < 5) {
					if (count) {
						await waitForCertainTime(count);
					}
					count += 1;
					orgs = (await getOrgs(userId))?.data;
				}
				setLoading(false);

				if (!orgs?.length) {
					history.push(`/empty-orgs`);
					return;
				}

				navigateToRoot();

				setRefreshTimestamp(new Date().toISOString());
			} else {
				setLoading(false);
				localStorage.removeItem('pendingInvitationId');
				sessionStorage.setItem('invite.error', 'true');
				history.push(`/logout`);
			}
		}

		const pendingInvitationId = localStorage.getItem('pendingInvitationId');
		if (pendingInvitationId) {
			acceptInvitation(pendingInvitationId);
		} else {
			setLoading(false);
			navigateToRoot();
		}
	}, [userId]);

	return loading;
}
