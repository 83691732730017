import { useEffect, useState } from 'react';
import { getOrgRoles, OrgRole } from '@flexera/services.iam';

/**
 * Internal hook to load the list of org roles based on the current org
 */
export function useFetchOrgRoles(orgId: number) {
	const [orgRoles, setOrgRoles] = useState<OrgRole[]>(null);
	useEffect(() => {
		async function fetchOrgRoles() {
			const response = await getOrgRoles(orgId);
			setOrgRoles(response.data);
		}

		if (orgId) {
			fetchOrgRoles();
		} else {
			setOrgRoles(null);
		}
	}, [orgId]);

	return orgRoles;
}
