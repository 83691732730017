import { useContext } from 'react';
import { NavContext } from './context/NavContext';
import { useClosestNavItem } from './useClosestNavItem';

/**
 * Returns the page title of the nav item closest to matching the current URL
 */
export function usePageTitle() {
	const { items } = useContext(NavContext);
	let navItem = useClosestNavItem();

	do {
		if (navItem?.label) {
			return navItem.label;
		}
		navItem = items[navItem?.parentId];
	} while (navItem);

	return '';
}
