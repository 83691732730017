import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Environment, environment } from '@flexera/lib.environment';
import { initiateEnforceSsoAction } from '@flexera/lib.auth';
import { useEnforceSsoData } from './useEnforceSsoData';

export const useEnforceSsoModal = () => {
	let timeout: number;
	const enforceSsoState = useEnforceSsoData();
	const dispatch = useDispatch();
	const [isOpen, setIsOpen] = useState(
		enforceSsoState && enforceSsoState.isOrgSsoEnforced
	);

	useEffect(() => {
		// if we are in a local environment, just throw the modal
		if (environment === Environment.Local && enforceSsoState?.isOrgSsoEnforced) {
			setIsOpen(enforceSsoState.isOrgSsoEnforced);
		}

		// if we are in staging or prod, throw the modal and initiate the redirect to the users Identity Provider
		if (environment !== Environment.Local && enforceSsoState?.isOrgSsoEnforced) {
			setIsOpen(enforceSsoState.isOrgSsoEnforced);
			timeout = setTimeout(() => {
				dispatch(initiateEnforceSsoAction(enforceSsoState.externalIdpId));
			}, 5000);
		}

		return () => clearTimeout(timeout);
	}, [enforceSsoState]);

	return { setIsOpen, isOpen };
};
