import { ComponentType } from 'react';

export interface Route {
	/** An arbitrary unique ID for the route */
	id: string;

	/** The URL path */
	path: string;

	/** The component to be rendered for that route */
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	component: ComponentType<any>;

	/** ID of the route's parent */
	parentId?: string;

	/** Only public routes are available to anonymous users */
	public?: boolean;

	/** Routes are sorted by priority from lowest to highest number. Default is 100. */
	priority?: number;

	/** This will match the route to the exact path */
	exact?: boolean;
	children?: Route[];
}

export type RouteDef = Omit<Route, 'children'>;
