import React from 'react';
import {
	Button,
	Modal,
	ModalContent,
	ModalFooter,
	P
} from '@flexera/ui.component-library';
import { t } from 'ttag';
import { initiateEnforceSsoAction } from '@flexera/lib.auth';
import { useDispatch } from 'react-redux';
import { useCurrentOrg } from '@flexera/shell.orgs';
import { useEnforceSsoData, useEnforceSsoModal } from '../../hooks';

const LiveModal = () => {
	const enforceSsoState = useEnforceSsoData();
	const dispatch = useDispatch();
	const org = useCurrentOrg();
	const { isOpen } = useEnforceSsoModal();

	return (
		<Modal
			width={'sm'}
			isOpen={isOpen}
			id={'global-enforce-sso-modal'}
			title={t`Single Sign-On Required.`}
		>
			<ModalContent>
				<P m={'0'} mb={'0'}>
					{t`${org.name} requires you to authenticate using Single Sign-On (SSO). You will be redirected to your SSO provider in just a moment.`}
				</P>
			</ModalContent>
			<ModalFooter>
				<Button
					type={'button'}
					title={'Accept this message'}
					id={'accept-enforce-sso'}
					width={'100%'}
					onClick={() => {
						dispatch(initiateEnforceSsoAction(enforceSsoState.externalIdpId));
					}}
				>
					{t`Take Me There`}
				</Button>
			</ModalFooter>
		</Modal>
	);
};

export { LiveModal };
