import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { useGdDrillThrough } from '@flexera/flexera-one.feature-flags';

const GoodDataClickHandlers: React.FC = ({ children }) => {
	const history = useHistory();
	const handleEvents = useGdDrillThrough();

	const determinePath = (input: object) => {
		if (
			input?.gdc?.event?.name === 'drillToUrlResolved' &&
			input?.gdc?.event?.data
		) {
			const { url } = input.gdc.event.data;
			if (url) {
				const mapAnalyzer = {
					amazon: 'aws-analyzer',
					microsoft: 'azure-analyzer',
					google: 'google-analyzer'
				};
				const _URL = new URL(url);
				const queryStr = new URLSearchParams(_URL.search);
				const spendCategory = queryStr.get('spendCategory') || '';
				let spendCategoryLower = spendCategory.toLowerCase();
				const vendor = queryStr.get('vendor') || '';
				const date = queryStr.get('date') || '';
				const monthYear = queryStr.get('monthYear') || '';
				const basePath = _URL.pathname.split('/').slice(0, 3).join('/');
				const query = `spendCategory=${spendCategory}&vendor=${vendor}&date=${date}`;
				const queryAlt = `spendCategory=${spendCategory}&monthYear=${monthYear}`;
				const analyzer = mapAnalyzer[vendor.toLowerCase()];
				let pathname;

				// sometimes this is in the path not the query
				if (!spendCategory) {
					if (url?.toLowerCase().indexOf('/saas') > 0) spendCategoryLower = 'saas';
					if (url?.toLowerCase().indexOf('/optima') > 0)
						spendCategoryLower = 'cloud';
				}

				// modify path on specific event data matches
				if (spendCategoryLower === 'cloud') {
					if (analyzer) {
						pathname = `${basePath}/optima/dashboard/${analyzer}?${query}`;
					} else {
						pathname = `${basePath}/optima/dashboard/default?${query}`;
					}
				} else if (monthYear) {
					if (spendCategoryLower === 'cloud') {
						pathname = `${basePath}/optima/dashboard/default?${queryAlt}`;
					} else if (spendCategoryLower === 'saas') {
						pathname = `${basePath}/saas/dashboard?${queryAlt}`;
					}
				} else if (spendCategoryLower === 'saas') {
					pathname = `${basePath}/saas/dashboard?${query}`;
				}

				// redirect only if there is a known redirect path
				if (pathname) {
					history.push(pathname);
				}
			}
		}
	};

	const handleMessageEvent = (event: MessageEvent) => {
		determinePath(event.data);
	};

	useEffect(() => {
		if (handleEvents) {
			window.addEventListener('message', handleMessageEvent);
		}

		return () => {
			window.removeEventListener('message', handleMessageEvent);
		};
	}, [handleEvents]);

	return children;
};

export { GoodDataClickHandlers };
