import { t } from 'ttag';

export const helpfulLinks = [
	{
		id: 0,
		title: t`SBOM Insights How-To Videos`,
		link:
			'https://www.youtube.com/playlist?list=PLnaFG2n4CcbO0labR1O_-WM4rea8i4_Mm'
	},
	{
		id: 1,
		title: t`Learning Center`,
		link:
			'https://learning.revenera.com/?_ga=2.185589164.449670393.1659497407-144823876.1658897032'
	},
	{
		id: 2,
		title: 'Revenera.com',
		link:
			'https://www.revenera.com/software-composition-analysis/products/sbom-insights'
	}
];
