import { HeaderProfile } from '@flexera/ui.component-library';
import { history } from '@flexera/lib.router-history';
import { useUserProfile, useLogout } from '@flexera/auth.base';
import { useHelpHref } from '@flexera/shell.navigation';
import React from 'react';

export const ProfileMenu = () => {
	const user = useUserProfile();
	const logout = useLogout();
	const helpHref = useHelpHref();

	return (
		<HeaderProfile
			id={'header-profile'}
			title={'Profile details'}
			name={user && `${user.firstName} ${user.lastName}`}
			email={user && user.email}
			menuItems={[
				{
					text: 'Help',
					href: helpHref,
					target: '_blank'
				},
				{
					text: 'Privacy Policy',
					href: 'https://www.flexera.com/legal/privacy-policy.html',
					target: '_blank'
				},
				{
					text: 'User Settings',
					onClick: (): void => {
						history.push('/settings/invitations', {
							backLink: location.pathname
						});
					}
				},
				{
					text: 'Log out',
					isLogout: true,
					onClick: logout
				}
			]}
		/>
	);
};
