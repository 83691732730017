import { NavItem } from './types/NavItem';
import { NavItemChild } from './types/NavItemChild';

/**
 * Filters nav items
 * @param value
 * @param navItem
 */

	export const filterNavItems = (value: string, navItem: NavItemChild[]) => {
		let searchRegex: RegExp;
		try {
			searchRegex = new RegExp(value, 'i');
		} catch (e) {
			// just swallow the error..
		}
	
		const validateSearch = (label: string) => {
			let pass: RegExpMatchArray | boolean = false;
	
			if (label && typeof label === 'string') {
				pass = label.match(searchRegex);
			}
	
			return pass;
		};
	
		const results: NavItem[] = [];
		// Goes through each Nav Item and dds it to results
		navItem
			.filter(i => !i.static)
			.forEach(item => {
				const categories: NavItemChild[] = [];
				const validItem = validateSearch(item.label);
				if (validItem) {
					results.push({ ...item });
					return;
				}
				const { children } = item;
				// Filters Nav Item Categories
				children.forEach(child => {
					const { label } = child;
					const validLabel = validateSearch(label);
					const filteredChildren = child.children.filter(sel =>
						validateSearch(sel.label)
					);
					// If Nav Item Category/Selection label matches, push all Category/Selection
					if (validLabel) {
						categories.push({
							...child
						});
						return;
					}
					// If Nav Category does not match, push only matching Selections
					if (!validLabel && filteredChildren.length > 0) {
						categories.push({
							...child,
							children: filteredChildren
						});
					}
				});
				results.push({
					...item,
					children: categories
				});
			});
		// Filter NavItems with no matches from results
		return results.filter(result => result.children.length > 0);
	};