import React, { Component } from 'react';
import { t } from 'ttag';
import { InfoBox } from '@flexera/ui.component-library';

interface State {
	hasError: boolean;
	error: Error | null;
}

export class GoodDataErrorBoundary extends Component {
	state: State = { hasError: false, error: null };

	componentDidCatch(error: Error) {
		this.setState({ hasError: true, error });
	}

	render() {
		if (this.state.hasError) {
			return (
				<InfoBox variant={'error'}>{t`An unknown error has occurred!`}</InfoBox>
			);
		}

		return this.props.children;
	}
}
