const makeId = (id: string) => `governance.bootstrap${id}`;

// admin
export const ADMIN_BASE = makeId('/admin');
export const ADMIN_LOADING = makeId('/admin.loading');
export const USERS = makeId('/admin/users');
export const GROUPS = makeId('/admin/groups');
export const ACCOUNTS = makeId('/admin/accounts');

// policies
export const POLICIES_BASE = makeId('/policies');
export const POLICIES_LOADING = makeId('/policies.loading');
export const DASHBOARD = makeId('/policies/dashboard');
export const CATALOG = makeId('/policies/catalog');
export const APPLIED = makeId('/policies/applied');
export const INCIDENTS = makeId('/policies/incidents');
export const TEMPLATES = makeId('/policies/templates');
export const CREDS = makeId('/policies/creds');
