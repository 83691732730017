import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Link } from 'react-router-dom';
import { NavItemChild } from '@flexera/shell.navigation';
import { Icon, IconMdOpenNewTab } from '@flexera/ui.component-library';
import { determineLinkTo } from './determineLinkTo';
import { addHighlightAroundSearchTerm } from './addHighlightAroundSearchTerm';
import {
	CategoryList,
	CategoryListDescription,
	FavoriteableListItem,
	GrandChildList
} from '../src/Submenu/Submenu.styled';
import { FavoriteButton } from '../src/Favorites/FavoritesButton/FavoriteButton';

export const getChildItems = (
	item: NavItemChild,
	searchIsActive,
	setSearchIsActive,
	searchWord,
	setIsActive?,
	setFlyoutOpen?
): JSX.Element => {
	const showSearchStyle = searchIsActive ? 'active-search-item' : '';

	const handleLinkClick = () => {
		if (setIsActive && setFlyoutOpen) {
			setFlyoutOpen(false);
			setIsActive(false);
		}
		if (item.onClick) {
			item.onClick();
		}
		if (setSearchIsActive) {
			setSearchIsActive(false);
		}
	};

	if (!item.label || item.hidden) {
		return;
	}

	if (item.category && !item.external) {
		const list = (
			<CategoryList
				tabIndex={-1}
				key={`${item.id}-${uuidv4()}`}
				aria-label={item.category}
				className={showSearchStyle}
			>
				<CategoryListDescription tabIndex={-1}>
					{searchIsActive
						? addHighlightAroundSearchTerm(item.category, searchWord)
						: item.category}
				</CategoryListDescription>
				<GrandChildList key={`${item.id}-${uuidv4()}-grandchild`} tabIndex={-1}>
					{item.children.map((grandChild) =>
						getChildItems(
							grandChild,
							searchIsActive,
							setSearchIsActive,
							searchWord,
							setIsActive,
							setFlyoutOpen
						)
					)}
				</GrandChildList>
			</CategoryList>
		);

		return list;
	}

	const { id } = item;
	return (
		<FavoriteableListItem
			tabIndex={-1}
			className={showSearchStyle}
			role={'menuitem'}
			key={`${id}-${uuidv4()}`}
			data-test-id={item.id}
		>
			{item.external && item.path ? (
				<a
					id={item.id as string}
					aria-label={item.label}
					href={determineLinkTo(item)}
					rel={item.path && 'noreferrer'}
					target={item.path && '_blank'}
					onClick={() => handleLinkClick()}
				>
					{searchIsActive
						? addHighlightAroundSearchTerm(item.label, searchWord)
						: item.label}
					<Icon
						color={'#BBB2B0'}
						position={'inherit'}
						src={IconMdOpenNewTab}
						height={'16px'}
						width={'16px'}
						viewBox={'0 0 26 26'}
						verticalAlign={'middle'}
						pl={'4px'}
					/>
				</a>
			) : (
				<Link
					id={item.id as string}
					aria-label={item.label}
					to={determineLinkTo(item)}
					onClick={() => handleLinkClick()}
				>
					{searchIsActive
						? addHighlightAroundSearchTerm(item.label, searchWord)
						: item.label}
					{item.external && (
						<Icon
							color={'#BBB2B0'}
							position={'inherit'}
							src={IconMdOpenNewTab}
							height={'16px'}
							width={'16px'}
							viewBox={'0 0 26 26'}
							verticalAlign={'middle'}
							pl={'4px'}
						/>
					)}
				</Link>
			)}
			<FavoriteButton itemId={id} />
		</FavoriteableListItem>
	);
};
