import { RouterProvider } from '@flexera/lib.router';
import { history } from '@flexera/lib.router-history';
import { StoreProvider } from '@flexera/shell.store';
import { DataProvider } from '@flexera/data-visualization.data';
import React, { ReactNode } from 'react';
import { debugContextDevtool } from 'react-context-devtool';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { VisualizationTypeProvider } from '@flexera/data-visualization.visualization/src/VisualizationTypeProvider';
import { DefaultRoute } from './DefaultRoute';
import { GlobalStyle } from './GlobalStyle';

/**
 * Renders the application shell
 * @param Layout A layout component (see @flexera/shell.app-layout for an example)
 * @param theme A theme object for the UI components
 * @param rootComponents Optional list of components to render at the root of the app
 */
export function renderShell(theme: any, rootComponents: ReactNode) {
	const div = document.createElement('div');
	div.id = 'shell-root';
	document.body.appendChild(div);

	const popoverRoot = document.createElement('div');
	popoverRoot.id = 'popover-root';
	document.body.appendChild(popoverRoot);

	const slideoutRoot = document.createElement('div');
	slideoutRoot.id = 'slideout-root';
	document.body.appendChild(slideoutRoot);

	const modalDiv = document.createElement('div');
	modalDiv.id = 'modal-root';
	document.body.appendChild(modalDiv);

	ReactDOM.render(
		<StoreProvider>
			<ThemeProvider theme={theme}>
				<>
					<GlobalStyle />
					<Router history={history}>
						<RouterProvider>
							<DataProvider>
								<VisualizationTypeProvider>
									<DefaultRoute />
									{rootComponents}
								</VisualizationTypeProvider>
							</DataProvider>
						</RouterProvider>
					</Router>
				</>
			</ThemeProvider>
		</StoreProvider>,
		div
	);

	debugContextDevtool(div, {
		disable:
			process.env.NODE_ENV === 'production' ||
			// eslint-disable-next-line no-underscore-dangle
			!window.__REACT_CONTEXT_DEVTOOL_GLOBAL_HOOK
	});
}
