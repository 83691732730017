import {
	ForgotPasswordPage,
	InvitationPage,
	LoginPage,
	SetPasswordPage
} from '@flexera/lib.auth';
import { useRoutes } from '@flexera/lib.router';
import { EmptyOrgsPage } from '@flexera/settings.main';
import { useEffect } from 'react';
import { Logout } from './Logout';
import { RouteIds } from './RouteIds';

/**
 * Sets up the authentication routes
 */
export function useAuthRoutes() {
	const { addRoutes } = useRoutes();

	useEffect(() => {
		return addRoutes([
			{
				id: RouteIds.login,
				path: '/login',
				public: true,
				component: LoginPage
			},
			{
				id: RouteIds.logout,
				path: '/logout',
				public: true,
				component: Logout
			},
			{
				id: RouteIds.forgotPassword,
				path: '/password/request',
				public: true,
				component: ForgotPasswordPage
			},
			{
				id: RouteIds.setPassword,
				path: '/password/reset',
				public: true,
				component: SetPasswordPage
			},
			{
				id: RouteIds.activation,
				path: '/invitations',
				public: true,
				component: InvitationPage
			},
			{
				id: RouteIds.emptyOrgs,
				path: '/empty-orgs',
				public: false,
				component: EmptyOrgsPage
			}
		]);
	}, []);
}
