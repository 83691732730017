export const XMinutes = 60000 * 30; // 30 mins

/**
 * Checks if the last time the user was active
 * was after the current session started
 * @param lastIdleTime
 * @param startTime
 * @returns
 */
export const checkIfUserHasBeenIdleForXMins = (
	lastIdleTime: number,
	currentTime: number,
	inactiveTime: number
) => {
	return currentTime >= lastIdleTime + inactiveTime;
};

/**
 * Checks if the session expiry time is in one minute's time or under
 * and checks if the user has not been active in the current session
 *
 * @param currentTime
 * @param startTime
 * @param expiryTime
 * @param lastIdleTime
 * @returns
 */
export const checkSessionWillTimeOut = (
	currentTime: number,
	lastIdleTime: number
) => {
	const oneMinute = 60000;
	const isIdle = checkIfUserHasBeenIdleForXMins(
		lastIdleTime,
		currentTime,
		XMinutes - oneMinute
	);
	return isIdle;
};

/**
 * Checks if the current time is at or after
 * the session time out time and checks if the
 * user has not been active in the current session
 *
 * @param currentTime
 * @param startTime
 * @param expiryTime
 * @param lastIdleTime
 * @returns
 */
export const checkSessionHasTimedOut = (
	currentTime: number,
	lastIdleTime: number
) => {
	const isIdle = checkIfUserHasBeenIdleForXMins(
		lastIdleTime,
		currentTime,
		XMinutes
	);
	return isIdle;
};

/**
 * Checks if the current session is about to expire
 * (with a 15 second offset to account for query time)
 * and checks if user was active during the sesssion
 * @param currentTime
 * @param startTime
 * @param expiryTime
 * @param lastIdleTime
 * @returns
 */
export const checkSessionHasExpiredAndIsActive = (
	currentTime: number,
	expiryTime: number,
	lastIdleTime: number
) => {
	const queryOffset = 15000;
	const nowIsAfterFromExpiry = currentTime >= expiryTime - queryOffset;
	const isIdle = checkIfUserHasBeenIdleForXMins(
		lastIdleTime,
		currentTime,
		XMinutes
	);
	return nowIsAfterFromExpiry && !isIdle;
};
