/* eslint-disable consistent-return */
import { useAuthenticated } from '@flexera/auth.base';
import { fnmsState, useFNMSCapabilityCheck } from '@flexera/fnms.shared';
import { addMessageAction } from '@flexera/lib.async-message';
import {
	loadingItem,
	MenuItemDef,
	useNavItems
} from '@flexera/shell.navigation';
import { OrgsLoading, useCurrentOrg, useOrgId } from '@flexera/shell.orgs';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

// converts the nav items to an acceptable format for the nav
const convert = (item: MenuItemDef, orgId: string) => {
	if (item.external) {
		return {
			...item,
			as: 'a',
			target: '_blank',
			href: item.path
		};
	}
	if (item.path) {
		return {
			...item,
			path: `/orgs/${orgId}/slo${item.path}`,
			urlMatch: new RegExp(
				`^\\/orgs\\/\\d+\\/slo\\/${item.path.replace('/', '')}(\\/|$|\\?)`
			)
		};
	}
	return {
		...item,
		category: item.label
	};
};

export function useNav() {
	const dispatch = useDispatch();
	const authenticated = useAuthenticated();
	// org comes from state
	const org = useCurrentOrg();
	// orgId comes from URL
	const orgId = useOrgId();
	const { addItems, removeItems } = useNavItems();
	let FNMSNavIds: string[];
	let handleTimeout: number = null;
	const { hasFNMS, loadingCurrentCapabilites } = useFNMSCapabilityCheck();

	useEffect(() => {
		// No nav for anonymous users
		if (!authenticated || !orgId) return;

		// We need to add a loading item so the app knows the navigation
		// is not yet ready
		const removeLoadingItem = addItems([loadingItem('fnms.bootstrap/loading')]);

		if (org === OrgsLoading || loadingCurrentCapabilites) {
			// If the orgs or capabilties are still loading, that's it...
			return removeLoadingItem;
		}

		if (org !== OrgsLoading && !loadingCurrentCapabilites && !hasFNMS) {
			// we are done loading orgs, and capabilities and we don't have fnms
			removeLoadingItem();
		}

		/**
		 * Function to listen to FNMS cookieRefreshed event coming up from hidden FNMSCookieRefresh iframe
		 * Sets the new navigation items sent up via the iframe
		 * @param e: MessageEvent
		 */
		const handlePostMessageEvent = (e: MessageEvent): null => {
			// if FNMS returns an error, we don't have nav items and we need to remove the loader
			if (e.data.fnmsState === fnmsState.error) {
				if (handleTimeout) {
					clearTimeout(handleTimeout);
				}
				removeLoadingItem();
			}
			if (
				e.data.fnmsState === fnmsState.cookieRefreshed &&
				!loadingCurrentCapabilites &&
				org !== OrgsLoading &&
				hasFNMS
			) {
				if (handleTimeout) {
					clearTimeout(handleTimeout);
				}

				removeLoadingItem();
				addItems(e.data.navigation.map((i: MenuItemDef) => convert(i, orgId)));
				FNMSNavIds = e.data.navigation.map((i: MenuItemDef) => i.id);
			}
			return null;
		};

		window.addEventListener('message', handlePostMessageEvent);

		if (hasFNMS) {
			// Wait for 10 seconds for something to happen, otherwise remove loading state
			handleTimeout = setTimeout(() => {
				removeLoadingItem();
				dispatch(
					addMessageAction({
						id: 'fnms-error',
						statusMessage:
							'IT Asset Management is taking longer than usual to load or is currently unavailable. Please try again later.',
						status: 'warning'
					})
				);
			}, 10000);
		}

		return () => {
			// we need to clear the navItems in the tree manually here before adding more to prevent dups
			if (FNMSNavIds) {
				removeItems(FNMSNavIds);
			}
			if (handleTimeout) {
				clearTimeout(handleTimeout);
			}
			removeLoadingItem();
			window.removeEventListener('message', handlePostMessageEvent);
		};
	}, [org, orgId, authenticated, loadingCurrentCapabilites, hasFNMS]);
}
