import produce, { Draft } from 'immer';
import { AnyAction, Reducer } from 'redux';
import { Context, Embed, GoodDataEmbedState, Status } from './state';
import {
	GET_DASHBOARD_URI_ACTION,
	GET_WORKSPACE_URI_ACTION,
	REGISTER_GOODDATA_EMBED_ACTION,
	SET_DASHBOARD_ID_ACTION,
	SET_EMBED_STATUS_ACTION,
	SET_WORKSPACE_URI_ACTION,
	SET_KPI_DASHBOARDS_ACTION,
	GET_KPI_DASHBOARDS_ACTION
} from './constants';
import { getProjectIDFromURI } from './service/GoodData';

export const initialEmbedState: Embed = {
	context: null,
	dashboardURI: '',
	dashboardId: '',
	projectId: null,
	status: Status.Init,
	statusMsg: '',
	workspaceURI: null,
	kpiDashboards: []
};

export const initialState: GoodDataEmbedState = {
	embeds: {}
};

export const gooddataEmbedReducer: Reducer<GoodDataEmbedState> = (
	state = initialState,
	action: AnyAction
) => {
	return produce<GoodDataEmbedState>(
		state,
		(draft: Draft<GoodDataEmbedState>) => {
			switch (action.type) {
				case REGISTER_GOODDATA_EMBED_ACTION:
					if (!draft.embeds[action.id]) {
						draft.embeds[action.id] = initialEmbedState;
					}
					break;
				case GET_WORKSPACE_URI_ACTION:
					draft.embeds[action.id] = {
						...initialEmbedState,
						status: Status.Loading
					};
					break;
				case SET_WORKSPACE_URI_ACTION:
					draft.embeds[action.id].status = Status.Success;
					draft.embeds[action.id].context = Context.Workspace;
					draft.embeds[action.id].workspaceURI = action.uri;
					draft.embeds[action.id].projectId = getProjectIDFromURI(action.uri);
					break;
				case SET_EMBED_STATUS_ACTION:
					if (draft.embeds[action.id]) {
						draft.embeds[action.id].status = action.status;

						if (action.statusMsg) {
							draft.embeds[action.id].statusMsg = action.statusMsg;
						}

						if (action.context) {
							draft.embeds[action.id].context = action.context;
						}
					}
					break;
				case GET_DASHBOARD_URI_ACTION:
					if (draft.embeds[action.id]) {
						draft.embeds[action.id].status = Status.Loading;
						draft.embeds[action.id].context = Context.Dashboard;
					}
					break;
				case SET_DASHBOARD_ID_ACTION:
					if (draft.embeds[action.id]) {
						draft.embeds[action.id].status = Status.Success;
						draft.embeds[action.id].context = Context.Dashboard;
						draft.embeds[action.id].dashboardId = action.data;
					}
					break;
				case GET_KPI_DASHBOARDS_ACTION:
					if (draft.embeds[action.id]) {
						draft.embeds[action.id].status = Status.Loading;
					}
					break;
				case SET_KPI_DASHBOARDS_ACTION:
					if (draft.embeds[action.id]) {
						draft.embeds[action.id].status = Status.KPISuccess;
						draft.embeds[action.id].context = Context.Dashboard;
						draft.embeds[action.id].kpiDashboards = action.data;
					}
					break;
				default: // no state return
			}
		}
	);
};
