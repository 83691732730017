/* eslint-disable no-restricted-syntax */
import React, { FC, useState } from 'react';
import { VisualizationType } from './VisualizationType';
import { VisualizationTypeContext } from './VisualizationTypeContext';
import { VisualizationTypeContextValue } from './VisualizationTypeContextValue';

const visualizationTypes: VisualizationType[] = [];
const visualizationTypeById: { [id: string]: VisualizationType } = {};

function addVisualizationTypes(newVisualizationTypes: VisualizationType[]) {
	newVisualizationTypes.forEach((visualizationType) => {
		const { id } = visualizationType;

		if (!visualizationTypeById[id]) {
			visualizationTypeById[id] = visualizationType;
			visualizationTypes.push(visualizationTypeById[id]);
		}
	});
}

export const VisualizationTypeProvider: FC = ({ children }) => {
	const [state, setState] = useState<VisualizationTypeContextValue>({
		visualizationTypes,
		visualizationTypeById,
		addVisualizationTypes: (newVisualizationTypes) => {
			addVisualizationTypes(newVisualizationTypes);
			return () => {
				setState({ ...state });
			};
		}
	});
	return (
		<VisualizationTypeContext.Provider value={state}>
			{children}
		</VisualizationTypeContext.Provider>
	);
};
