import { useRoutes as useRoutesHook } from '@flexera/lib.router';
import { OrgsRouteId } from '@flexera/shell.orgs-routes';
import * as Pages from '@flexera/automation.bootstrap';
import { useEffect } from 'react';
import { GovernanceApp } from './GovernanceApp';

export function useRoutes() {
	const { addRoutes } = useRoutesHook();

	useEffect(() => {
		return addRoutes([
			{
				id: 'governance.bootstrap/policies',
				parentId: OrgsRouteId,
				path: '/policy/projects',
				exact: false,
				component: GovernanceApp
			},
			{
				id: 'automation.bootstrap/catalog',
				parentId: OrgsRouteId,
				path: '/policy/catalog',
				exact: true,
				component: Pages.Catalog
			},
			{
				id: 'governance.bootstrap/users',
				parentId: OrgsRouteId,
				path: '/admin/users',
				exact: false,
				component: GovernanceApp
			},
			{
				id: 'governance.bootstrap/groups',
				parentId: OrgsRouteId,
				path: '/admin/groups',
				exact: false,
				component: GovernanceApp
			},
			{
				id: 'governance.bootstrap/accounts',
				parentId: OrgsRouteId,
				path: '/admin/accounts',
				exact: false,
				component: GovernanceApp
			}
		]);
	}, []);
}
