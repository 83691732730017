import { t } from 'ttag';
import { GOODDATA_DOMAIN } from '@flexera/lib.api';
import { queryStringBuilder } from '@flexera/lib.utilities';
import { IGoodDataMessage } from './Interfaces';

export interface DashboardTemplateOptions {
	query?: { [key: string]: string };
}

export const GET_GD_EVENT_REASON = (message: IGoodDataMessage) => {
	let reason = '';
	switch (message.name) {
		case 'platform.upgrade':
			reason = t`GoodData platform is being upgraded to a newer version`;
			break;
		case 'platform.maintenance':
			reason = t`GoodData platform is under maintenance`;
			break;
		case 'project.maintenance':
			reason = t`Project is under maintenance`;
			break;
		case 'platform.error':
		default:
			reason = t`An error occurred while loading the GoodData application`;
			break;
	}
	return reason;
};

export const KPI_DASHBOARD_TEMPLATE = (
	projectID: string,
	dashboardID: string
): string => {
	return `https://${GOODDATA_DOMAIN}/dashboards/embedded/#/project/${projectID}/dashboard/${dashboardID}?redirect-drill-url-to-message=all`;
};

export const CUSTOM_DASHBOARD_TEMPLATE = (
	dataProductId: string,
	clientId: string,
	currentDashboardId: string
): string => {
	return `https://${GOODDATA_DOMAIN}/dashboards/embedded/#/product/${dataProductId}/client/${clientId}/dashboard/${currentDashboardId}`;
};

export const CUSTOM_NEW_DASHBOARD_TEMPLATE = (
	dataProductId: string,
	clientId: string
): string => {
	return `https://${GOODDATA_DOMAIN}/dashboards/embedded/#/product/${dataProductId}/client/${clientId}/new-dashboard`;
};

export const CUSTOM_NEW_DASHBOARD_NOCLIENT_TEMPLATE = (
	projectID: string
): string => {
	return `https://${GOODDATA_DOMAIN}/dashboards/embedded/#/project/${projectID}/new-dashboard?redirect-drill-url-to-message=all`;
};

export const ANALYTICAL_DESIGNER_TEMPLATE = (
	dataProductId: string,
	clientId: string
): string => {
	return `https://${GOODDATA_DOMAIN}/analyze/embedded/#/client/${dataProductId}:${clientId}`;
};

export const ANALYTICAL_DESIGNER_WORKSPACE_TEMPLATE = (
	projectID: string
): string => {
	return `https://${GOODDATA_DOMAIN}/analyze/embedded/#/${projectID}`;
};

export const PP_DASHBOARD_TEMPLATE = (
	workspaceURI: string,
	dashboardURI: string,
	options?: DashboardTemplateOptions
): string => {
	const query = queryStringBuilder(options && options.query);
	return `https://${GOODDATA_DOMAIN}/dashboard.html${query}#project=${workspaceURI}&dashboard=${dashboardURI}&nochrome=true&override=ui.link&redirect-drill-url-to-message=all`;
};
