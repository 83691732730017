import { Admin } from '@flexera/admin.bootstrap';
import { useFnmsIframe } from '@flexera/flexera-one.feature-flags';
import { FNMSCookieRefreshIframe } from '@flexera/fnms.bootstrap';
import { Governance } from '@flexera/governance.bootstrap';
import { Settings } from '@flexera/settings.bootstrap';
import { V1Layout } from '@flexera/shell.v1-layout';
import React from 'react';
import { MenuIds } from './MenuIds';
import { useV1Routes } from './useV1Routes';
import { Nav } from './Nav';

export const InitV1App = () => {
	useV1Routes();
	const showFnms = useFnmsIframe();

	return (
		<Nav>
			{showFnms && <FNMSCookieRefreshIframe rootNav={MenuIds.ItAssets} />}
			<Settings />
			<Governance />
			<Admin />
			<V1Layout />
		</Nav>
	);
};
