import {
	GET_DASHBOARD_URI_ACTION,
	GET_WORKSPACE_URI_ACTION,
	REGISTER_GOODDATA_EMBED_ACTION,
	SET_DASHBOARD_ID_ACTION,
	SET_WORKSPACE_URI_ACTION,
	SET_EMBED_STATUS_ACTION,
	GET_KPI_DASHBOARDS_ACTION,
	SET_KPI_DASHBOARDS_ACTION
} from './constants';
import { Status, Context } from './state';
import { QueryOptions } from './service/GoodData';

export const registerGoodDataEmbedAction = (id: string) => ({
	type: REGISTER_GOODDATA_EMBED_ACTION,
	id
});

export const getWorkspaceURIAction = (
	orgId: string,
	id: string,
	capability: string,
	dataProduct?: string
) => ({
	type: GET_WORKSPACE_URI_ACTION,
	orgId,
	id,
	capability,
	dataProduct
});

export const setWorkspaceURIAction = (id: string, uri: string) => ({
	type: SET_WORKSPACE_URI_ACTION,
	id,
	uri
});

export const setEmbedStatusAction = (
	id: string,
	status: Status,
	statusMsg?: string,
	context?: Context
) => ({
	type: SET_EMBED_STATUS_ACTION,
	id,
	status,
	statusMsg,
	context
});

export const getDashboardURIAction = (
	id: string,
	projectId: string,
	dashboardIdentifier: string,
	dashboardType: string,
	queryOptions?: QueryOptions
) => ({
	type: GET_DASHBOARD_URI_ACTION,
	id,
	projectId,
	dashboardIdentifier,
	dashboardType,
	queryOptions
});

export const setDashboardId = (id: string, data: any) => ({
	type: SET_DASHBOARD_ID_ACTION,
	id,
	data
});

export const getKPIDashboards = (id: string, projectId: string) => ({
	type: GET_KPI_DASHBOARDS_ACTION,
	id,
	projectId
});

export const setKPIDashboards = (id: string, data: any) => ({
	type: SET_KPI_DASHBOARDS_ACTION,
	id,
	data
});
