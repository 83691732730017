import styled from 'styled-components';
import { BaseTheme } from '@flexera/ui.component-library';

export const GoodDataEmbedStyled = styled.iframe`
	border-radius: ${BaseTheme.radii.md};
	border: none;
	height: 75vh;
	width: 100%;
`;

export const GoodDataEmbedWrapper = styled.section`
	width: auto;
	height: auto;
	box-shadow: ${BaseTheme.shadows.small};
	border-radius: ${BaseTheme.radii.md};
	background-color: ${BaseTheme.colors.light};
`;
