import React, { FC, useState } from 'react';
import { LayoutContext } from './LayoutContext';

export const LayoutProvider: FC = ({ children }) => {
	const [navExpanded, setNavExpanded] = useState(true);
	return (
		<LayoutContext.Provider
			value={{ navExpanded, toggle: () => setNavExpanded(!navExpanded) }}
		>
			{children}
		</LayoutContext.Provider>
	);
};
