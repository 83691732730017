import { useContext } from 'react';
import { helpDocBaseUrl } from '@flexera/lib.utilities';
import { NavContext } from './context/NavContext';
import { useClosestNavItem } from './useClosestNavItem';

/**
 * Returns the help HREF of the nav item closest to matching the current URL
 */
export function useHelpHref() {
	// enable after the actual help doc url is available for each route
	const { items } = useContext(NavContext);
	let navItem = useClosestNavItem();

	do {
		if (navItem?.helpHref) return navItem.helpHref;
		navItem = items[navItem?.parentId];
	} while (navItem);

	// fall back to root of help docs
	return `${helpDocBaseUrl}/SBOM_Insights_root.htm`;
}
