import React from 'react';
import { useCurrentOrg } from '@flexera/shell.orgs';
import { HeaderEnvironmentIndicator } from '@flexera/ui.component-library';
import { Environment, environment } from '@flexera/lib.environment';
import { BEFO_SANDBOX } from '@flexera/fnms.shared';

const fnmsHostOverride = localStorage.getItem('fnmsHostOverride');

function clearFromLocalStorage(orgId: number): null {
	// eslint-disable-next-line no-alert
	if (
		// eslint-disable-next-line no-alert
		!window.confirm(
			`Clear FNMS host override value and go back to ${BEFO_SANDBOX}?`
		)
	) {
		return;
	}
	localStorage.removeItem('fnmsHostOverride');
	// Then, reload the app. Go to the root because it's possible we're on a page that doesn't exist in the other branch.
	window.location.href = `https://${window.location.host}/orgs/${orgId}/fnms`;
}

/**
 * For FNMS dev purposes. Throws an indicator for what the iFrame src has been set to if it's been overridden
 */
const HostIndicator = () => {
	const org = useCurrentOrg();

	return (
		<>
			{fnmsHostOverride && environment !== Environment.Production && (
				<HeaderEnvironmentIndicator
					id={'fnms-host-indicator'}
					environment={`FNMS at: ${fnmsHostOverride}`}
					onClick={() => clearFromLocalStorage(org.id)}
				/>
			)}
		</>
	);
};

export { HostIndicator };
