import React from 'react';
import { NavItemChild } from './NavItemChild';


export interface NavItem {
	/** Determines if the link should render as something else */
	as?: string;
	/** Child routes */
	children?: NavItemChild[];
	/** Type of element */
	component?: React.ComponentType<any, any>;
	/** Text value of Top Level Route */
	label?: string;
	/** Path for documentation */
	docPath?: string;
	/** An SVG Icon used for Top Level Route */
	icon?: React.FunctionComponent<React.SVGAttributes<SVGElement>>;
	/** HTML ID */
	id?: number | string;
	/** Path within Fusion */
	path?: string;
	/** Route ID */
	routeId?: number;
	/** Override the default onClick behavior */
	onClick?: () => boolean;
	/** Nav Item is Private */
	private?: boolean;

	static: boolean;
}
