import React, { useContext } from 'react';
import {
	BaseTheme,
	Icon,
	IconMdFavorited,
	IconMdFavorite
} from '@flexera/ui.component-library';
import { getSavedFavorites, updateFavorites } from '@flexera/shell.favorites';
import { useMenu, NavItemChild } from '@flexera/shell.navigation';
import { AddRemoveFaveButton } from './FavoriteButton.styled';
import { getNavigationFavoriteItemFromId } from '../../../utilities/getNavigationFavoriteItemFromId';
import { handleKeyboardAccess } from '../../../utilities/keyboardHandlers';
import { NavigationContext } from '../../navigationContext';

export const FavoriteButton = ({ itemId }) => {
	const menu = useMenu();
	const { orgId, savedFavorites, setSavedFavorites } = useContext(
		NavigationContext
	);

	const isFavorite =
		savedFavorites?.findIndex((favorite) => favorite?.itemId === itemId) >= 0;

	const removeNavFavorite = async () => {
		const favoriteItems = await getSavedFavorites(orgId);
		const updatedFavorites = favoriteItems.filter(
			(favorite) => favorite?.itemId !== itemId
		);
		updateFavorites(orgId, updatedFavorites);
		setSavedFavorites(updatedFavorites);
	};

	const addNavFavorite = async () => {
		const favoriteItems = (await getSavedFavorites(orgId)) || [];
		const newFavoriteItem = getNavigationFavoriteItemFromId(
			itemId,
			menu as NavItemChild[]
		);
		if (newFavoriteItem) {
			updateFavorites(orgId, favoriteItems, newFavoriteItem);
			setSavedFavorites([...favoriteItems, newFavoriteItem]);
		}
	};

	const handleFavoriteClick = (event) => {
		event.preventDefault();
		event.stopPropagation();

		if (isFavorite) {
			return removeNavFavorite();
		}

		return addNavFavorite();
	};

	return (
		<AddRemoveFaveButton
			onKeyPress={(e) => {
				handleKeyboardAccess(e, handleFavoriteClick, e);
			}}
			onClick={handleFavoriteClick}
			className={isFavorite ? 'favorite-active' : 'favorite-not-active'}
		>
			<Icon
				src={isFavorite ? IconMdFavorited : IconMdFavorite}
				cursor={'pointer'}
				color={BaseTheme.colors.light}
			/>
		</AddRemoveFaveButton>
	);
};
