import { lazyLoaded } from '@flexera/lib.utilities';

export const BarChart = lazyLoaded(async () => {
	const mod = import('./components/BarChart');
	return { default: (await mod).BarChart };
});

export const LineChart = lazyLoaded(async () => {
	const mod = import('./components/LineChart');
	return { default: (await mod).LineChart };
});
