import { adminNavIds } from '@flexera/admin.bootstrap';
import { govNavIds } from '@flexera/governance.bootstrap';
import { settingsNavIds } from '@flexera/settings.bootstrap';
import { NavOverrides, NavProvider } from '@flexera/shell.navigation';
import {
	IconFxAppCatalog,
	IconFxConnector,
	IconFxCalculator,
	IconMdAddUser,
	IconMdCloud,
	IconMdCloudDownload,
	IconMdDashboard,
	IconMdEmail,
	IconMdLineGraph,
	IconMdPayment,
	IconMdRequests,
	IconMdSettings,
	IconMdUser,
	IconMdUserGroup,
	IconMdView
} from '@flexera/ui.component-library';
import React, { FC } from 'react';
import { MenuIds } from './MenuIds';
import { SetupNavMenu } from './SetupNavMenu';

const esc = (s: string) => s.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
const c = (s: string) => RegExp(`${esc(s)}.+`);

const overrides = new NavOverrides([
	// Admin
	[
		adminNavIds.INVITATIONS,
		{ icon: IconMdEmail, parentId: MenuIds.Administration }
	],
	[adminNavIds.IDPS, { icon: IconMdAddUser, parentId: MenuIds.Administration }],
	// Governance
	[
		govNavIds.DASHBOARD,
		{ parentId: MenuIds.Policies, label: 'Dashboard', priority: 0 }
	],
	[c(govNavIds.POLICIES_BASE), { parentId: MenuIds.Policies }],
	[
		govNavIds.USERS,
		{
			parentId: MenuIds.Administration,
			icon: IconMdUser
		}
	],
	[
		govNavIds.GROUPS,
		{
			priority: 2,
			parentId: MenuIds.Administration,
			icon: IconMdUserGroup
		}
	],
	[
		govNavIds.ACCOUNTS,
		{
			priority: 3,
			parentId: MenuIds.Administration,
			icon: IconMdCloud
		}
	],
	// Settings
	[c(settingsNavIds.BASE), { parentId: MenuIds.Settings }]
]);

export const Nav: FC = ({ children }) => {
	return (
		<NavProvider overrides={overrides}>
			<SetupNavMenu />
			{children}
		</NavProvider>
	);
};
