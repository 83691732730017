import { lazyLoaded } from '@flexera/lib.utilities';
import { withCapability } from '@flexera/lib.permissions';

export const stratusDashboard = withCapability(
	lazyLoaded(async () => {
		const mod = import('@flexera/stratus.main');
		return { default: (await mod).Dashboard };
	}),
	'sbom'
);

export const registerBucket = withCapability(
	lazyLoaded(async () => {
		const mod = import('@flexera/stratus.main');
		return { default: (await mod).RegisterBucket };
	}),
	'sbom'
);

export const manageSbomWrapper = withCapability(
	lazyLoaded(async () => {
		const mod = import('@flexera/stratus.main');
		return { default: (await mod).ManageSbomWrapper };
	}),
	'sbom'
);

export const importSbom = withCapability(
	lazyLoaded(async () => {
		const mod = import('@flexera/stratus.main');
		return { default: (await mod).ImportSbom };
	}),
	'sbom'
);

export const jobs = withCapability(
	lazyLoaded(async () => {
		const mod = import('@flexera/stratus.main');
		return { default: (await mod).Jobs };
	}),
	'sbom'
);

export const bucketsGrid = withCapability(
	lazyLoaded(async () => {
		const mod = import('@flexera/stratus.main');
		return { default: (await mod).BucketsGrid };
	}),
	'sbom'
);

export const preferences = withCapability(
	lazyLoaded(async () => {
		const mod = import('@flexera/stratus.main');
		return { default: (await mod).Preferences };
	}),
	'sbom'
);
