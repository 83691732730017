import React, { FC } from 'react';
import { ChildRoutes } from '@flexera/lib.router';
import { useLocation } from 'react-router-dom';
import { Navigation as NavigationMenu } from '@flexera/ui.navigation';
import { Header } from '@flexera/header';
import { useMenu, NavItem, usePageTitle } from '@flexera/shell.navigation';
import {
	AsyncMessageStateModule,
	AsyncMessages
} from '@flexera/lib.async-message';
import { SessionTimeout } from '@flexera/lib.inactivity';
import { PollingReportsWrapper } from '@flexera/sbom-reports';
import { GridLayout } from '../styles/AppLayout';

interface props {
	menuItems: NavItem[];
}

const MainAppGrid: FC<props> = ({ menuItems }: props) => {
	const documentTitle = usePageTitle();
	const location = useLocation();
	const flexeraTitle = 'SBOM Insights';

	if (!/^\/orgs\/\d+\/itv\/custom-dashboard\/.+/.test(location.pathname)) {
		document.title = documentTitle
			? `${documentTitle} - ${flexeraTitle}`
			: flexeraTitle;
	}

	return (
		<GridLayout>
			<SessionTimeout />
			<Header />
			<NavigationMenu items={menuItems} />
			<AsyncMessageStateModule>
				<ChildRoutes />
				<AsyncMessages />
				<PollingReportsWrapper />
			</AsyncMessageStateModule>
		</GridLayout>
	);
};

export const AppLayout: FC = () => {
	const menuItems = useMenu();

	return menuItems && menuItems.length > 0 ? (
		<MainAppGrid menuItems={menuItems as NavItem[]} />
	) : (
		<ChildRoutes />
	);
};
