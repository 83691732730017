import React, { FC, useEffect } from 'react';
import {
	GoodDataEmbedStyled,
	GoodDataEmbedWrapper
} from './GoodDataEmbedStyles';
import {
	ANALYTICAL_DESIGNER_WORKSPACE_TEMPLATE,
	ANALYTICAL_DESIGNER_TEMPLATE
} from '../service/Constants';
import { IGoodDataEvent, IGoodDataKPIMessage } from '../service/Interfaces';
import { isValidGDMessageHost } from '../service/GoodData';

interface Props {
	overrideDashboardProps?: {
		workspaceId?: string;
		dashboardId?: string;
	};
	id?: string;
	projectId?: string;
	dataProductId?: string;
	clientId?: string;
	defaultHeight?: number;
	overrideHeight?: string;
	onMessageReceived?: (event: IGoodDataKPIMessage) => void;
}

export const GoodDataAnalyticalEmbed: FC<Props> = ({
	overrideDashboardProps,
	dataProductId,
	clientId,
	id = 'gooddata-analytical-designer',
	defaultHeight = 600,
	onMessageReceived,
	overrideHeight
}) => {
	const src = overrideDashboardProps
		? ANALYTICAL_DESIGNER_WORKSPACE_TEMPLATE(overrideDashboardProps.workspaceId)
		: ANALYTICAL_DESIGNER_TEMPLATE(dataProductId, clientId);

	const onGoodDataMessage = ({ origin, data }: IGoodDataEvent) => {
		if (!isValidGDMessageHost(origin)) return;

		if (data.gdc) {
			const { height } = data.gdc.event.data;
			switch (data.gdc.event.name) {
				case 'resized':
					if (height > defaultHeight && !overrideHeight) {
						document.getElementById(id).style.height = `${height}px`;
					}
					break;
				default:
					break;
			}
			if (typeof onMessageReceived === 'function') {
				return onMessageReceived(data);
			}
		}
	};

	useEffect(() => {
		window.addEventListener('message', onGoodDataMessage, false);
		return () => window.removeEventListener('message', onGoodDataMessage);
	}, []);

	return (
		<GoodDataEmbedWrapper>
			<GoodDataEmbedStyled
				key={'analytical'}
				style={{
					height: overrideHeight || `${defaultHeight}px`,
					padding: '0'
				}}
				id={id}
				src={src}
			/>
		</GoodDataEmbedWrapper>
	);
};
