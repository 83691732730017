import {
	SET_USER_AUTH_IAM_SUCCESS_ACTION,
	SET_SESSION_TIMEOUT_STATE_ACTION,
	SET_SESSION_ENDED_STATE_ACTION,
	SET_ENFORCE_SSO_ACTION,
	CALL_TOKEN_REFRESH_ACTION,
	CALL_TOKEN_REFRESH_COMPLETE_ACTION,
	RESET_TOKEN_REFRESH_ACTION
} from '@flexera/lib.auth';
import jwtDecode from 'jwt-decode';
import { IamAuthState } from './State';

function decodeUserId(token: string) {
	if (!token) {
		return null;
	}
	try {
		const { user } = jwtDecode<{ user: number }>(token);
		return user;
	} catch (e) {
		// eslint-disable-next-line no-console
		console.error('An invalid auth token from local storage has been ignored');
		return null;
	}
}

const initialState: IamAuthState = {
	userId: decodeUserId(localStorage.getItem('access_token')),
	tokenState: {
		tokenRefreshComplete: false
	},
	sessionState: {
		sessionWillTimeOut: '',
		sessionHasEnded: false
	}
};

export const reducer = (state: IamAuthState = initialState, action) => {
	if (action.type === SET_USER_AUTH_IAM_SUCCESS_ACTION) {
		// Yes, yes, reducers shouldn't have side effects...
		// but setting user id and access token at different times cause issues
		localStorage.setItem('access_token', action.code);
		return {
			userId: decodeUserId(action.code)
		};
	}

	if (action.type === SET_SESSION_TIMEOUT_STATE_ACTION) {
		return {
			...state,
			sessionState: {
				...state.sessionState,
				sessionWillTimeOut: action.sessionWillTimeOut
			}
		};
	}

	if (action.type === SET_ENFORCE_SSO_ACTION) {
		return {
			...state,
			enforceSsoState: {
				isOrgSsoEnforced: action.isOrgSsoEnforced,
				externalIdpId: action.externalIdpId
			}
		};
	}

	if (action.type === SET_SESSION_ENDED_STATE_ACTION) {
		return {
			...state,
			sessionState: {
				sessionWillTimeOut: 'close',
				sessionHasEnded: action.sessionHasEnded
			}
		};
	}

	if (action.type === CALL_TOKEN_REFRESH_COMPLETE_ACTION) {
		return {
			...state,
			tokenState: {
				tokenRefreshComplete: true
			}
		};
	}

	if (
		action.type === CALL_TOKEN_REFRESH_ACTION ||
		action.type === RESET_TOKEN_REFRESH_ACTION
	) {
		return {
			...state,
			tokenState: {
				tokenRefreshComplete: false
			}
		};
	}
	return state;
};
