import React from 'react';
import { Link } from 'react-router-dom';
import { useBreadcrumbs } from '@flexera/shell.navigation';
import { HeaderBreadcrumbsStyled } from '../styles/HeaderBreadcrumbs';

export const Breadcrumbs = () => {
	const breadcrumbs = useBreadcrumbs();
	if (!breadcrumbs) return null;
	return (
		<HeaderBreadcrumbsStyled>
			{breadcrumbs.map(
				(
					breadcrumb: { label?: string; path?: string; id?: string },
					index: number
				): JSX.Element => {
					const { label, path, id } = breadcrumb;
					return (
						<li key={id}>
							{path ? (
								<Link to={path}>{label}</Link>
							) : (
								<span style={{ margin: index === 0 ? '0 0.25rem 0 0' : '0 0.25rem' }}>
									{label}
								</span>
							)}
							{breadcrumbs.length !== index + 1 && `/`}
						</li>
					);
				}
			)}
		</HeaderBreadcrumbsStyled>
	);
};
