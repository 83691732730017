import React, { FC } from 'react';
import { Environment, environment } from '@flexera/lib.environment';
import {
	useFNMSCapabilityCheck,
	useFNMSCookieRefresh,
	useFNMSErrorMessage,
	useFNMSIframeURLs
} from '@flexera/fnms.shared';
import { StyledFNMSCookieRefreshIframe } from './Styled';
import { useNav, useRoutes } from './utils';

/**
 * Throws the hidden FNMS iframe responsible for sending up postMessages with info such as loading states and navigation items
 * Initializes hook to listen to for postMessages coming up from the FNMS cookie refresh iframe
 */
export const FNMSCookieRefreshIframe: FC = () => {
	const fnmsCookieRefreshUrl = localStorage.getItem('fnmsCookieRefreshUrl');
	const cookieRefreshIframe = useFNMSCookieRefresh();
	const { hasFNMS } = useFNMSCapabilityCheck();
	const { FNMSCookieRefreshIframeSrc } = useFNMSIframeURLs();
	useRoutes();
	useNav();
	useFNMSErrorMessage();

	return (
		<>
			{hasFNMS && (
				<StyledFNMSCookieRefreshIframe
					id={'fnms-cookie-refresh'}
					ref={cookieRefreshIframe}
					src={
						fnmsCookieRefreshUrl && environment !== Environment.Production
							? fnmsCookieRefreshUrl
							: FNMSCookieRefreshIframeSrc
					}
				/>
			)}
		</>
	);
};
