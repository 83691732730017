import { useAuthenticated } from '@flexera/auth.base';
import { Permissions } from '@flexera/governance.shared';
import { usePermissionSummary } from '@flexera/lib.permissions';
import { hasIAM, hasPolicies } from '@flexera/lib.permissions/src/permissions';
import { helpDocBaseUrl } from '@flexera/lib.utilities';
import {
	ADMIN__IDENTITY,
	DASHBOARDS,
	POLICIES
} from '@flexera/shell.base-nav-tree';
import { loadingItem, useNavItems } from '@flexera/shell.navigation';
import { OrgsLoading, useCurrentOrg, useOrgId } from '@flexera/shell.orgs';
import { useEffect } from 'react';
import { t } from 'ttag';
import ActivePoliciesIcon from './icons/icon-active-policies.svg';
import PolicyCatalogIcon from './icons/icon-policy-catalog.svg';
import PolicyCredentialsIcon from './icons/icon-policy-credentials.svg';
import PolicyDashboardIcon from './icons/icon-policy-dashboard.svg';
import PolicyIncidentsIcon from './icons/icon-policy-incidents.svg';
import PolicyLibraryIcon from './icons/icon-policy-library.svg';
import * as ids from './menuIds';

export function useNav() {
	const authenticated = useAuthenticated();
	// org comes from state
	const org = useCurrentOrg();
	// orgId comes from URL
	const orgId = useOrgId();
	const { addItems } = useNavItems();

	const [perms, loadingPerms] = usePermissionSummary(
		`/orgs/${orgId}`,
		hasPolicies,
		hasIAM,
		Permissions.userIndex,
		Permissions.userShow,
		Permissions.orgUserShow,
		Permissions.accessRuleIndex,
		Permissions.groupIndex,
		Permissions.groupShow,
		Permissions.appliedPolicyIndex,
		Permissions.publishedTemplateIndex,
		Permissions.incidentShow,
		Permissions.policyTemplateShow,
		Permissions.cloudCredentialsIndex
	);

	useEffect(() => {
		if (!authenticated || !orgId) return;

		if (org === OrgsLoading || loadingPerms) {
			return addItems([
				loadingItem(ids.ADMIN_LOADING),
				loadingItem(ids.POLICIES_LOADING)
			]);
		}

		return addItems(
			[
				perms.get(hasIAM) &&
					perms.get(Permissions.groupIndex) &&
					perms.get(Permissions.groupShow) && {
						id: ids.GROUPS,
						parentId: ADMIN__IDENTITY,
						path: `/orgs/${orgId}/admin/groups`,
						helpHref: `${helpDocBaseUrl}/Creating_and_Managing_Groups.htm`,
						urlMatch: /^\/orgs\/\d+\/admin\/groups(\/|$|\?)/,
						label: t`Groups`,
						priority: 3
					},
				perms.get(hasIAM) &&
					(perms.get(Permissions.userIndex) || perms.get(Permissions.userShow)) && {
						id: ids.ACCOUNTS,
						parentId: ADMIN__IDENTITY,
						path: `/orgs/${orgId}/admin/accounts`,
						urlMatch: /^\/orgs\/\d+\/admin\/accounts(\/|$|\?)/,
						label: t`Accounts`,
						priority: 2
					}
				// perms.get(hasPolicies) &&
				// 	perms.get(Permissions.appliedPolicyIndex) && {
				// 		id: ids.DASHBOARD,
				// 		parentId: DASHBOARDS,
				// 		path: `/orgs/${orgId}/policy/projects//dashboard`,
				// 		urlMatch: /^\/orgs\/\d+\/policy\/projects\/.*\/dashboard(\/|$|\?)/,
				// 		label: t`Automation`,
				// 		priority: 40,
				// 		icon: PolicyDashboardIcon
				// 	},
				// perms.get(hasPolicies) &&
				// 	perms.get(Permissions.publishedTemplateIndex) && {
				// 		id: ids.CATALOG,
				// 		parentId: POLICIES,
				// 		path: `/orgs/${orgId}/policy/catalog`,
				// 		urlMatch: /^\/orgs\/\d+\/policy\/catalog(\/|$|\?)/,
				// 		label: t`Catalog`,
				// 		priority: 0,
				// 		icon: PolicyCatalogIcon
				// 	},
				// perms.get(hasPolicies) &&
				// 	perms.get(Permissions.appliedPolicyIndex) && {
				// 		id: ids.APPLIED,
				// 		parentId: POLICIES,
				// 		path: `/orgs/${orgId}/policy/projects//applied-policies`,
				// 		urlMatch: /^\/orgs\/\d+\/policy\/projects\/.*\/applied-policies(\/|$|\?)/,
				// 		label: t`Applied Policies`,
				// 		priority: 1,
				// 		icon: ActivePoliciesIcon
				// 	},
				// perms.get(hasPolicies) &&
				// 	perms.get(Permissions.incidentShow) && {
				// 		id: ids.INCIDENTS,
				// 		parentId: POLICIES,
				// 		path: `/orgs/${orgId}/policy/projects//incidents`,
				// 		urlMatch: /^\/orgs\/\d+\/policy\/projects\/.*\/incidents(\/|$|\?)/,
				// 		label: t`Incidents`,
				// 		priority: 2,
				// 		icon: PolicyIncidentsIcon
				// 	},
				// perms.get(hasPolicies) &&
				// 	perms.get(Permissions.policyTemplateShow) && {
				// 		id: ids.TEMPLATES,
				// 		parentId: POLICIES,
				// 		path: `/orgs/${orgId}/policy/projects//templates/`,
				// 		urlMatch: /^\/orgs\/\d+\/policy\/projects\/.*\/templates(\/|$|\?)/,
				// 		label: 'Templates',
				// 		priority: 3,
				// 		icon: PolicyLibraryIcon
				// 	},
				// perms.get(hasPolicies) &&
				// 	perms.get(Permissions.cloudCredentialsIndex) && {
				// 		id: ids.CREDS,
				// 		parentId: POLICIES,
				// 		path: `/orgs/${orgId}/policy/projects//credentials/`,
				// 		urlMatch: /^\/orgs\/\d+\/policy\/projects\/.*\/credentials(\/|$|\?)/,
				// 		label: t`Credentials`,
				// 		priority: 4,
				// 		icon: PolicyCredentialsIcon
				// 	}
			].filter(Boolean)
		);
	}, [org, orgId, authenticated, loadingPerms, perms]);
}
