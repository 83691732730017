import styled from 'styled-components';
import { theme } from './theme';

export const HeaderBreadcrumbsStyled = styled.ul`
	display: flex;
	font-size: ${theme.fontSizes.xs};
	padding-left: 0;

	li {
		align-items: center;
		display: flex;
		list-style: none;

		a {
			text-decoration: none;
			margin: 0 ${theme.space.xxs};
			color: ${theme.colors.text};
		}

		&:last-of-type {
			a {
				font-weight: ${theme.fontWeights.semiBold};
				color: ${theme.colors.primary};
			}
		}

		&:first-child a {
			margin-left: 0;
		}
	}
`;
