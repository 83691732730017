import { useContext } from 'react';
import { MenuItem } from './context/MenuItem';
import { NavContext } from './context/NavContext';
import { useClosestNavItem } from './useClosestNavItem';

export function useBreadcrumbs() {
	const { items } = useContext(NavContext);
	let navItem = useClosestNavItem();
	const itemsArray: MenuItem[] = [];

	// Gets the nav items most closely matching the current url; need to use
	// unshift to get objects in proper order for accessing the labels
	do {
		if (navItem) itemsArray.unshift(navItem);
		navItem = items[navItem?.parentId];
	} while (navItem);

	return itemsArray;
}
