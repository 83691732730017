import React from 'react';
import { Provider } from 'react-redux';
import { store } from '../../module';
import { EnforceSsoModal } from './EnforceSsoModal';

const EnforceSso = () => (
	<Provider store={store}>
		<EnforceSsoModal />
	</Provider>
);

export { EnforceSso };
