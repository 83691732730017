import { Navigation } from '@flexera/ui.component-library';
import React, { useContext } from 'react';
import { LayoutContext } from '~/context/LayoutContext';
import { useLeftNav } from './useLeftNav';

export const Nav = () => {
	const { navExpanded } = useContext(LayoutContext);
	const menuItems = useLeftNav();

	return (
		<Navigation
			id={'navigation'}
			title={'Main Menu'}
			isExpanded={navExpanded}
			items={menuItems}
		/>
	);
};
